import { alpha } from "@mui/material/styles";

export const getRoleChipStyle = (role, theme) => {
  switch (role) {
    case "admin":
      return {
        backgroundColor: alpha(theme.palette.success.main, 0.1),
        color: "#41ae19",
        border: "1px solid #97d67e",
      };
    case "tech":
      return {
        backgroundColor: alpha(theme.palette.error.main, 0.1),
        color: theme.palette.error.main,
        border: "1px solid #f1b0a5",
      };
    case "manager":
      return {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
        color: "#d79c13",
        border: "1px solid #f4d795",
      };
    case "foamer":
      return {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.primary.main,
        border: "1px solid #b0c3fe",
      };
    case "unassigned":
      return {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[500],
        border: "1px solid #dbdbdb",
      };
    default:
      return {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[500],
        border: "1px solid #dbdbdb",
      };
  }
};
