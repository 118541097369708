import React, { useContext, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleGoBack = () => {
    navigate('/dashboard'); // Adjust this route as necessary
  };

  return (
    <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Submission Successful!
      </Typography>
      <Typography variant="subtitle1">
        Your form has been successfully submitted. Thank you for your attention to detail.
      </Typography>
      <Button
        variant="contained"
        sx={{ mt: 3 }}
        onClick={handleGoBack}
      >
        Go Back to Dashboard
      </Button>
    </Box>
  );
};

export default SuccessPage;
