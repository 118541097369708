import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  FormHelperText,
  Divider,
  Stack,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MainCard from "components/MainCard"; // Assuming you have this component
import { useFirebase } from "../contexts/FirebaseContext";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { useFormik } from "formik";
import * as yup from "yup";

// Helper function to format phone number as XXX-XXX-XXXX
const formatPhoneNumber = (value) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return value;
};

// Validation schema for the profile form
const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  phone: yup.string().required("Phone number is required"),
  password: yup.string().min(6, "Password should be at least 6 characters"),
  notificationPreference: yup.string(), // Optional validation for managers
});

const EditUserProfile = () => {
  const { user, userData } = useFirebase();
  const [loading, setLoading] = useState(false);

  // Initialize formik with empty values, and update them when userData is available
  const formik = useFormik({
    initialValues: {
      name: "", // Leave initial as empty and set later
      phone: "", // Leave initial as empty and set later
      password: "",
      notificationPreference: "email", // Default to email for managers
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          name: values.name,
          phone: formatPhoneNumber(values.phone), // Ensure formatted phone is saved
          notificationPreference: values.notificationPreference, // Update notification preference
        });

        // If password is changed
        if (values.password) {
          await auth.currentUser.updatePassword(values.password);
        }

        alert("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile:", error);
        alert("Failed to update profile");
      } finally {
        setLoading(false);
      }
    },
  });

  // Update form values when userData is available
  useEffect(() => {
    if (userData) {
      formik.setValues({
        name: userData.name || `${userData.firstName} ${userData.lastName}`,
        phone: userData.phone || "",
        password: "",
        notificationPreference: userData.notificationPreference || "email",
      });
    }
  }, [userData]); // Trigger when userData changes

  return (
    <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
      {/* Welcome Message */}
      <Typography variant="h5" sx={{ mb: 2 }}>
        Welcome, {userData?.firstName}! {/* Use first name dynamically */}
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Please ensure your profile information is correct. If everything looks
        good, let's get started.
      </Typography>

      {/* Button to QC Procedure */}
      <Stack direction="row" spacing={2} sx={{ mb: 6 }}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink} // Using RouterLink for navigation
          to="/dashboard/" // Update this with your actual QC Procedure route
        >
          Let's get started
        </Button>
      </Stack>

      <form onSubmit={formik.handleSubmit}>
        <MainCard title="Edit Profile">
          <Grid container spacing={3} alignItems="center">
            {/* Name Field */}
            <Grid
              item
              xs={12}
              sm={3}
              lg={4}
              sx={{ pt: { xs: 2, sm: "0 !important" } }}
            >
              <InputLabel
                sx={{
                  textAlign: { xs: "left", sm: "right" },
                  fontWeight: 900,
                }}
              >
                Name:
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
              <TextField
                fullWidth
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <FormHelperText sx={{ mb: 2 }}>
                Enter your full name
              </FormHelperText>
            </Grid>

            {/* Phone Number Field */}
            <Grid
              item
              xs={12}
              sm={3}
              lg={4}
              sx={{ pt: { xs: 2, sm: "0 !important" } }}
            >
              <InputLabel
                sx={{
                  textAlign: { xs: "left", sm: "right" },
                  fontWeight: 900,
                }}
              >
                Phone Number:
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
              <TextField
                fullWidth
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formik.values.phone}
                onChange={(e) => {
                  const formattedPhone = formatPhoneNumber(e.target.value);
                  formik.setFieldValue("phone", formattedPhone); // Format as the user types
                }}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <FormHelperText sx={{ mb: 2 }}>
                Enter a valid phone number
              </FormHelperText>
            </Grid>

            {/* Divider */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Password Field */}
            <Grid
              item
              xs={12}
              sm={3}
              lg={4}
              sx={{ pt: { xs: 2, sm: "0 !important" } }}
            >
              <InputLabel
                sx={{
                  textAlign: { xs: "left", sm: "right" },
                  fontWeight: 900,
                }}
              >
                Password:
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
              <TextField
                fullWidth
                id="password"
                name="password"
                type="password"
                placeholder="Change your password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <FormHelperText sx={{ mb: 3 }}>
                Enter a new password if you want to change it
              </FormHelperText>
            </Grid>

            {/* Notification Preference for Managers */}
            {userData?.role === "manager" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={4}
                  sx={{ pt: { xs: 2, sm: "0 !important" } }}
                >
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Notification Preference:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="notificationPreference"
                      name="notificationPreference"
                      value={formik.values.notificationPreference}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email"
                      />
                      <FormControlLabel
                        value="sms"
                        control={<Radio />}
                        label="SMS"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}

            {/* Divider */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update Profile"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </form>
    </Grid>
  );
};

export default EditUserProfile;
