import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, List, useMediaQuery } from "@mui/material";

// project import
import NavItem from "./NavItem";
import { useFirebase } from "contexts/FirebaseContext";
import useConfig from "hooks/useConfig";
import { MenuOrientation } from "config";

// Define all menu items with their respective roles
const allMenuItems = [
  { title: "Admin Dashboard", url: "/dashboard/admin", roles: ["admin"] },
  { title: "Tech Dashboard", url: "/dashboard/tech", roles: ["admin", "tech"] },
  { title: "Setup Gun", url: "/dashboard/setup-gun", roles: ["admin", "tech"] },
  { title: "Messages", url: "/dashboard/messages", roles: ["admin", "tech"] },
  {
    title: "Tutorial Library",
    url: "/dashboard/tutorial-library",
    roles: ["admin", "tech"],
  },
  {
    title: "Manager Dashboard",
    url: "/dashboard/manager",
    roles: ["admin", "manager"],
  },
  {
    title: "QC Procedure",
    url: "/dashboard/foamer",
    roles: ["admin", "foamer"],
  },
  {
    title: "Pending Activation",
    url: "/unassigned",
    roles: ["admin", "unassigned"],
  },
];

const Navigation = () => {
  const theme = useTheme();
  const { menuOrientation } = useConfig();
  const { user } = useFirebase();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !matches;
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const userRole = user?.role;

  useEffect(() => {
    // Filter the items that match the user's role
    const roleBasedMenuItems = allMenuItems.filter((item) =>
      item.roles.includes(userRole)
    );

    // Update the state with the filtered items
    setFilteredMenuItems(roleBasedMenuItems);
  }, [userRole]); // Depend on userRole to re-run effect when it changes

  // Map over the filtered menu items to create NavItem components
  const navItems = filteredMenuItems.map((item, index) => (
    <NavItem key={index} item={item} level={1} />
  ));

  return (
    <Box sx={{ pt: isHorizontal ? 0 : 2 }}>
      <List disablePadding>{navItems}</List>
    </Box>
  );
};

export default Navigation;
