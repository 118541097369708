import React, {
  createContext,
  useState,
  useEffect,
  useReducer,
  useContext,
} from "react";
import {
  getAuth,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "./firebaseOperations";
import { LOGIN, LOGOUT, LOADING } from "./auth-reducer/actions";
import authReducer from "./auth-reducer/auth";
import Loader from "../components/Loader";
import useFetchUserData from "hooks/useFetchUserData";
import { useQuery } from "@tanstack/react-query";

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    loading: true,
  });
  const [error, setError] = useState(null);
  // console.log("Inside FirebaseContext.js - state:", state);
  const { userData, loading: userDataLoading } = useFetchUserData(
    state.user?.uid
  );

  // Add logging to track user data
  useEffect(() => {
    // console.log("FirebaseProvider initialized");

    if (!state.user) {
      // console.log("No user is logged in.");
    } else {
      // console.log("User is logged in:", state.user);
    }
  }, [state.user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch custom claims
        const idTokenResult = await user.getIdTokenResult();

        // Check if the user is marked as a former employee
        if (idTokenResult.claims.formerEmployee) {
          // Immediately log the user out if they are marked as a former employee
          alert(
            "You have been marked as a Former Employee and have been logged out."
          );
          await signOut(auth);
        } else {
          // Fetch user data from Firestore if not marked as Former Employee
          const userRef = doc(db, `users/${user.uid}`);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            dispatch({
              type: LOGIN,
              payload: { isLoggedIn: true, user: { ...user, ...userData } },
            });
          } else {
            dispatch({ type: LOGOUT });
          }
          dispatch({ type: "SET_INITIALIZED", payload: true });
        }
      } else {
        dispatch({ type: LOGOUT });
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to update the user as a "Former Employee"
  const disableUserLogin = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);

      // Update Firestore with "Former Employee" data
      await updateDoc(userRef, {
        name: "Former Employee",
        email: "former.employee@example.com",
        phone: "111-222-3344",
      });

      console.log("User profile updated to Former Employee");
    } catch (error) {
      console.error("Error disabling user login:", error);
    }
  };

  // Action creators for sign in, sign out, etc
  const firebaseSignIn = (email, password) => {
    dispatch({ type: LOADING });
    // try {
    return signInWithEmailAndPassword(email, password);
    // } catch (error) {
    //   setError(error);
    //   console.error("Error during sign in: ", error)
    // }
  };
  const firebaseRegister = (email, password) => {
    dispatch({ type: LOADING });
    return createUserWithEmailAndPassword(email, password);
  };
  const firebaseSignOut = () => {
    dispatch({ type: LOADING });
    return signOut();
  };
  const firebaseResetPassword = async (email) => {
    const auth = getAuth(); // Get the correct auth instance

    if (typeof email !== "string") {
      throw new Error("Invalid email format");
    }

    try {
      await sendPasswordResetEmail(auth, email); // Call sendPasswordResetEmail with the correct auth instance
      console.log("Password reset email sent!");
    } catch (error) {
      console.error("Password reset error:", error.message);
      throw error;
    }
  };

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        userLoading: state.loading,
        firebaseRegister,
        firebaseSignIn,
        firebaseSignOut,
        firebaseResetPassword,
        userData,
        userDataLoading,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);
