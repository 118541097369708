import { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import TechCompaniesList from "components/CompanyList";
import { DownloadOutlined, CaretDownOutlined } from "@ant-design/icons";

// ==============================|| DASHBOARD - ANALYTICS ||============================== //

const DashboardTech = () => {
  const theme = useTheme();
  const [slot, setSlot] = useState("week");
  const [quantity, setQuantity] = useState("By volume");

  const handleQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment) setSlot(newAlignment);
  };

  return (
    <>
      <Grid container rowSpacing={4.5} columnSpacing={3}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Company List</Typography>
            </Grid>
          </Grid>
          <MainCard content={false} sx={{ mt: 1.5 }}>
            <Grid item>
              <Grid container>
                <Grid item xs={12}>
                  <TechCompaniesList />
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardTech;
