import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "contexts/FirebaseContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../firebase";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import AnimateButton from "components/@extended/AnimateButton";
import ResultCard from "./ResultCard";
import { calculateRatioData, prepareDocData } from "../utils/ratioUtils";
import { fetchSlugGunSystemData } from "../utils/dataFetchers";
import ErrorAlert from "./ErrorAlert";

// Adjust this to your specific validation needs
const validationSchema = yup.object({
  ISOWeight: yup.number().required("ISO Weight is required"),
  PolyWeight: yup.number().required("Poly Weight is required"),
  bagWeight: yup.number().required("Bag Weight is required"),
  conditioningRoomTemperature: yup
    .number()
    .required("Conditioning Room Temperature is required")
    .min(75, "Temperature must be at least 75ºF")
    .max(82, "Temperature must not exceed 82ºF"),
  chemicalLevelCylinderISO: yup
    .number()
    .required("% Chemical Level in Cylinder A is required"),
  chemicalLevelCylinderPoly: yup
    .number()
    .required("% Chemical Level in Cylinder B is required"),
  nitrogenSetPressureTank: yup
    .number()
    .required("Nitrogen Reserves in Tank A is required"),
  nitrogenReservesTank: yup
    .number()
    .required("Nitrogen Reserves in Tank B is required"),
  tankPressureISO: yup.number().required("Tank pressure - A is required"),
  tankPressurePoly: yup.number().required("Tank pressure - B is required"),
  filterTemperatureISO: yup
    .number()
    .required("Filter Temperature - A is required"),
  filterTemperaturePoly: yup
    .number()
    .required("Filter Temperature - B is required"),
});

const ThirdFormComponent = () => {
  const navigate = useNavigate();
  const { user } = useFirebase();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDetails, setErrorDetails] = useState({
    ratio: false,
    throughputDrop: false,
    ppmOutput: false,
    visualCheck: false,
  });
  const [docId, setDocId] = useState(null);
  const [slugGunSystemData, setSlugGunSystemData] = useState(null);
  const [isSlugGunLoading, setIsSlugGunLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.slugGunRef) {
        setIsSlugGunLoading(true);
        const data = await fetchSlugGunSystemData(user.slugGunRef);
        setSlugGunSystemData(data);
        setIsSlugGunLoading(false);
      }
    };

    fetchData();

    try {
      const storedErrorDetails = JSON.parse(
        localStorage.getItem("errorDetails")
      );
      if (storedErrorDetails) {
        setErrorDetails(storedErrorDetails);
        setIsError(true);
        // console.log("Stored error details:", storedErrorDetails);
      }
    } catch (e) {
      console.error("Error parsing error details from localStorage:", e);
    }
  }, [user?.slugGunRef]);

  const formik = useFormik({
    initialValues: {
      ISOWeight: "",
      PolyWeight: "",
      bagWeight: "",
      visualCheck: "yes", // Default value
      conditioningRoomTemperature: "",
      chemicalLevelCylinderISO: "",
      chemicalLevelCylinderPoly: "",
      nitrogenSetPressureTank: "",
      nitrogenReservesTank: "",
      tankPressureISO: "",
      tankPressurePoly: "",
      filterTemperatureISO: "",
      filterTemperaturePoly: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error("User not authenticated");

        const ratioData = calculateRatioData(
          values,
          slugGunSystemData,
          slugGunSystemData?.durationBagShot || 0
        );
        const docData = prepareDocData(
          values,
          user,
          currentUser,
          slugGunSystemData,
          ratioData,
          "thirdForm"
        );

        // console.log(ratioData);

        const docRef = await addDoc(collection(db, "formEntries"), docData);
        setDocId(docRef.id);

        const isOffRatio = ratioData.isOffRatio;
        const ratioError =
          ratioData.ratio < slugGunSystemData?.ratioLowerLimit ||
          ratioData.ratio > slugGunSystemData?.ratioUpperLimit;
        const throughputDropError = ratioData.throughputDrop > 8;
        const ppmOutputError =
          ratioData.poundsPerMinute < slugGunSystemData?.outputLowerLimit ||
          ratioData.poundsPerMinute > slugGunSystemData?.outputUpperLimit;

        setErrorDetails({
          ratio: !ratioError,
          throughputDrop: !throughputDropError,
          ppmOutput: !ppmOutputError,
          visualCheck: values.visualCheck === "yes",
        });

        setIsSubmitted(true);
        setIsError(false);

        if (ratioData.isOffRatio) {
          // console.log("Navigating to call page with docId: ", docRef.id);
          navigate("/dashboard/foamer/call", { state: { docId: docRef.id } });
        } else {
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error("Error writing document to Firestore", error);
        setIsError(true);
        setErrorMessage(
          "An error occurred while submitting the form: " + error.message
        );
        setIsSubmitted(false);
      }
    },
  });

  const gunNumber = isSlugGunLoading
    ? "Loading..."
    : slugGunSystemData
      ? `Slug Gun being used = ${slugGunSystemData.machineName}`
      : "No slugGun assigned";

  const durationBagShot = slugGunSystemData?.durationBagShot || "10"; // Default to 10 if not available

  return (
    <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
      {isSubmitted ? (
        <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <ResultCard
                bagWeight={formik.values.bagWeight}
                durationBagShot={slugGunSystemData?.durationBagShot}
                docId={docId}
                showIssueFix={true}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
            {isError && (
              <ErrorAlert
                errorMessage={errorMessage}
                errorDetails={errorDetails}
              />
            )}
            <MainCard title={gunNumber}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    {durationBagShot} Second Ratio Shot:
                  </Typography>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    Conditioning Room :
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Temperature (ºF) :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="conditioningRoomTemperature"
                        name="conditioningRoomTemperature"
                        placeholder="fahrenheit"
                        type="tel"
                        value={formik.values.conditioningRoomTemperature}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.conditioningRoomTemperature &&
                          Boolean(formik.errors.conditioningRoomTemperature)
                        }
                        helperText={
                          formik.touched.conditioningRoomTemperature &&
                          formik.errors.conditioningRoomTemperature
                        }
                      />
                      <FormHelperText>
                        Please enter temperature in Fahrenheit
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    Chemical Level in Cylinders :
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        ISO :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="chemicalLevelCylinderISO"
                        name="chemicalLevelCylinderISO"
                        placeholder="Level in %"
                        type="tel"
                        value={formik.values.chemicalLevelCylinderISO}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.chemicalLevelCylinderISO &&
                          Boolean(formik.errors.chemicalLevelCylinderISO)
                        }
                        helperText={
                          formik.touched.chemicalLevelCylinderISO &&
                          formik.errors.chemicalLevelCylinderISO
                        }
                      />
                      <FormHelperText>
                        Please enter your estimated level.
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Poly :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="chemicalLevelCylinderPoly"
                        name="chemicalLevelCylinderPoly"
                        placeholder="Level in %"
                        type="tel"
                        value={formik.values.chemicalLevelCylinderPoly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.chemicalLevelCylinderPoly &&
                          Boolean(formik.errors.chemicalLevelCylinderPoly)
                        }
                        helperText={
                          formik.touched.chemicalLevelCylinderPoly &&
                          formik.errors.chemicalLevelCylinderPoly
                        }
                      />
                      <FormHelperText>
                        Please enter your estimated level.
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    Nitrogen Supply :
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Set Pressure :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="nitrogenSetPressureTank"
                        name="nitrogenSetPressureTank"
                        placeholder="psi"
                        type="tel"
                        value={formik.values.nitrogenSetPressureTank}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.nitrogenSetPressureTank &&
                          Boolean(formik.errors.nitrogenSetPressureTank)
                        }
                        helperText={
                          formik.touched.nitrogenSetPressureTank &&
                          formik.errors.nitrogenSetPressureTank
                        }
                      />
                      <FormHelperText>
                        Set Pressure is what is put into the tanks.
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Reserve :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="nitrogenReservesTank"
                        name="nitrogenReservesTank"
                        placeholder="psi"
                        type="tel"
                        value={formik.values.nitrogenReservesTank}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.nitrogenReservesTank &&
                          Boolean(formik.errors.nitrogenReservesTank)
                        }
                        helperText={
                          formik.touched.nitrogenReservesTank &&
                          formik.errors.nitrogenReservesTank
                        }
                      />
                      <FormHelperText>
                        What's available in the bottles.
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    Tank Pressure :
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        ISO :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="tankPressureISO"
                        name="tankPressureISO"
                        placeholder="Pressure"
                        type="tel"
                        value={formik.values.tankPressureISO}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.tankPressureISO &&
                          Boolean(formik.errors.tankPressureISO)
                        }
                        helperText={
                          formik.touched.tankPressureISO &&
                          formik.errors.tankPressureISO
                        }
                      />
                      <FormHelperText>
                        Please enter the tank pressure data.
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Poly :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="tankPressurePoly"
                        name="tankPressurePoly"
                        placeholder="Pressure"
                        type="tel"
                        value={formik.values.tankPressurePoly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.tankPressurePoly &&
                          Boolean(formik.errors.tankPressurePoly)
                        }
                        helperText={
                          formik.touched.tankPressurePoly &&
                          formik.errors.tankPressurePoly
                        }
                      />
                      <FormHelperText>
                        Please enter the tank pressure data.
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    Filter Temperature :
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Temperature (ºF) - ISO :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="filterTemperatureISO"
                        name="filterTemperatureISO"
                        placeholder="fahrenheit"
                        type="tel"
                        value={formik.values.filterTemperatureISO}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.filterTemperatureISO &&
                          Boolean(formik.errors.filterTemperatureISO)
                        }
                        helperText={
                          formik.touched.filterTemperatureISO &&
                          formik.errors.filterTemperatureISO
                        }
                      />
                      <FormHelperText>
                        Please enter temperature in Fahrenheit.
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Temperature (ºF) - Poly :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="filterTemperaturePoly"
                        name="filterTemperaturePoly"
                        placeholder="fahrenheit"
                        type="tel"
                        value={formik.values.filterTemperaturePoly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.filterTemperaturePoly &&
                          Boolean(formik.errors.filterTemperaturePoly)
                        }
                        helperText={
                          formik.touched.filterTemperaturePoly &&
                          formik.errors.filterTemperaturePoly
                        }
                      />
                      <FormHelperText>
                        Please enter temperature in Fahrenheit.
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    {durationBagShot} Second Ratio Shot:
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        ISO Weight :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="ISOWeight"
                        name="ISOWeight"
                        placeholder="grams"
                        type="tel"
                        value={formik.values.ISOWeight}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.ISOWeight &&
                          Boolean(formik.errors.ISOWeight)
                        }
                        helperText={
                          formik.touched.ISOWeight && formik.errors.ISOWeight
                        }
                      />
                      <FormHelperText sx={{ mb: 2 }}>
                        Please enter the weight in grams
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Poly Weight :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="PolyWeight"
                        name="PolyWeight"
                        placeholder="grams"
                        type="tel"
                        value={formik.values.PolyWeight}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.PolyWeight &&
                          Boolean(formik.errors.PolyWeight)
                        }
                        helperText={
                          formik.touched.PolyWeight && formik.errors.PolyWeight
                        }
                      />
                      <FormHelperText sx={{ mb: 2 }}>
                        Please enter the weight in grams
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Bag Weight :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <TextField
                        fullWidth
                        id="bagWeight"
                        name="bagWeight"
                        placeholder="grams"
                        type="tel"
                        value={formik.values.bagWeight}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.bagWeight &&
                          Boolean(formik.errors.bagWeight)
                        }
                        helperText={
                          formik.touched.bagWeight && formik.errors.bagWeight
                        }
                      />
                      <FormHelperText sx={{ mb: 3 }}>
                        Please enter the weight in grams
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Visual Check:
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={4}
                      sx={{ mt: 2, pt: { xs: 2, sm: "0 !important" } }}
                    >
                      <InputLabel
                        sx={{
                          textAlign: { xs: "left", sm: "right" },
                          fontWeight: 900,
                        }}
                      >
                        Does it pass the visual check :
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6} sx={{ mt: 2 }}>
                      <Grid container spacing={{ xs: 0.5, sm: 2 }}>
                        <Grid
                          item
                          xs={12}
                          sx={{ pt: { xs: 2, sm: "0 !important", mt: 4 } }}
                        >
                          <FormControl
                            error={
                              formik.touched.visualCheck &&
                              Boolean(formik.errors.visualCheck)
                            }
                          >
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="visualCheck"
                              value={formik.values.visualCheck}
                              onChange={formik.handleChange}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label={
                                  <Typography sx={{ ml: 1 }}>Yes</Typography>
                                }
                                sx={{ ml: 1, mr: 4 }}
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label={
                                  <Typography sx={{ ml: 1 }}>No</Typography>
                                }
                                sx={{ mr: 2 }}
                              />
                            </RadioGroup>
                            {formik.touched.visualCheck &&
                              formik.errors.visualCheck && (
                                <Box sx={{ width: "100%", mt: 2 }}>
                                  <Alert
                                    severity="error"
                                    sx={{ justifyContent: "center" }}
                                  >
                                    <AlertTitle
                                      sx={{
                                        fontWeight: 900,
                                        fontSize: "1.25rem",
                                        color: "error",
                                      }}
                                    >
                                      Error
                                    </AlertTitle>
                                    {formik.errors.visualCheck}
                                  </Alert>
                                </Box>
                              )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <Grid container justifyContent="flex-end">
                      <AnimateButton>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </AnimateButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </form>
      )}
    </Grid>
  );
};

export default ThirdFormComponent;
