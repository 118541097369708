import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { useFirebase } from "../contexts/FirebaseContext";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  Timestamp,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LinearWithIcon from "components/@extended/progress/LinearWithIcon";
import {
  ArrowRightOutlined,
  CheckCircleFilled,
  WarningFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

const TechCompaniesList = () => {
  const theme = useTheme();
  const [companies, setCompanies] = useState([]);
  const { user } = useFirebase();

  // Define a color map for different statuses
  const statusColorMap = {
    green: "success",
    yellow: "warning",
    red: "error",
  };

  // Helper function to create a slug from a company name
  const createSlug = (companyName) => {
    return companyName
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
  };

  useEffect(() => {
    if (user && user.uid) {
      console.log("Fetching companies for user:", user.uid);

      const fetchCompanies = async () => {
        const techRef = doc(db, "users", user.uid);
        const q = query(
          collection(db, "companies"),
          where("techRef", "==", techRef)
        );
        const querySnapshot = await getDocs(q);
        console.log("Query Snapshot:", querySnapshot.docs.length);

        if (querySnapshot.empty) {
          console.log("No companies found for the technician.");
          return;
        }

        const companiesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          companyName: doc.data().companyName,
          status: "green", // Default status
          slug: createSlug(doc.data().companyName), // Generate slug for each company
        }));

        // Sort companies alphabetically
        companiesData.sort((a, b) =>
          a.companyName.localeCompare(b.companyName)
        );

        // Check for recent off-ratio events
        const now = Timestamp.now();
        const sevenDaysAgo = new Timestamp(
          now.seconds - 7 * 24 * 60 * 60,
          now.nanoseconds
        );

        const offRatioQuery = query(
          collection(db, "formEntries"),
          where("timestamp", ">=", sevenDaysAgo)
        );
        const offRatioSnapshot = await getDocs(offRatioQuery);

        if (!offRatioSnapshot.empty) {
          const offRatioEntries = offRatioSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          companiesData.forEach((company) => {
            const companyEntries = offRatioEntries.filter(
              (entry) => entry.company === company.companyName
            );

            const offRatioCount = companyEntries.filter(
              (entry) => entry.isOffRatio
            ).length;
            const exactNumbersCount = companyEntries.filter(
              (entry) => !entry.isOffRatio && entry.exactNumbers
            ).length; // Assuming exactNumbers is a boolean field

            if (offRatioCount >= 1) {
              company.status = "red";
            } else if (exactNumbersCount >= 3) {
              company.status = "yellow";
            } else {
              company.status = "green";
            }
          });
        }

        setCompanies(companiesData);
      };

      fetchCompanies();
    }
  }, [user]);

  if (!user) {
    return (
      <Typography variant="body1" sx={{ margin: 2 }}>
        Loading user data...
      </Typography>
    );
  }

  if (!companies.length) {
    return (
      <Typography variant="body1" sx={{ margin: 2 }}>
        No companies found for this technician.
      </Typography>
    );
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.length > 0 ? (
            companies.map((company) => (
              <TableRow key={company.id}>
                <TableCell>
                  <Link
                    to={`company/${company.id}/${company.slug}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ fontWeight: "bold" }}
                      startIcon={<ArrowRightOutlined />}
                    >
                      {company.companyName}
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  <LinearWithIcon
                    value={100}
                    color={statusColorMap[company.status]}
                    icon={
                      company.status === "red" ? (
                        <CloseCircleFilled
                          style={{ color: theme.palette.error.main }}
                        />
                      ) : company.status === "yellow" ? (
                        <WarningFilled
                          style={{ color: theme.palette.warning.main }}
                        />
                      ) : (
                        <CheckCircleFilled
                          style={{ color: theme.palette.success.main }}
                        />
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: "center" }}>
                No companies found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TechCompaniesList;
