import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Updated import
import { DeleteOutlined, AddCircleOutline } from "@mui/icons-material";
import { EditOutlined } from "@ant-design/icons";

const MachinesList = ({ companyRef }) => {
  const theme = useTheme();
  const [machines, setMachines] = useState([]);
  const navigate = useNavigate(); // Updated hook

  useEffect(() => {
    const fetchMachines = async () => {
      if (!companyRef) return;

      const q = query(
        collection(db, "machines"),
        where("companyRef", "==", companyRef)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No machines found for this company.");
      } else {
        const machinesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMachines(machinesData);
      }
    };

    fetchMachines();
  }, [companyRef]);

  // Add this function inside the MachinesList component
  const handleAddSystem = (companyName) => {
    console.log(`Navigating to setup-gun for company: ${companyName}`);
    navigate(
      `/dashboard/setup-gun?companyName=${encodeURIComponent(companyName)}`
    );
  };

  const handleEdit = (machineId, companyName) => {
    navigate(`/dashboard/setup-gun?machineId=${machineId}`);
  };

  const handleDelete = async (machineId) => {
    console.log(
      "Deleting machine with ID:",
      machineId,
      "Type:",
      typeof machineId
    );
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this system?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "machines", String(machineId)));
        setMachines((prevMachines) =>
          prevMachines.filter((machine) => machine.id !== machineId)
        );
        console.log("System deleted successfully");
      } catch (error) {
        console.error("Error deleting system:", error);
      }
    }
  };

  if (!machines.length) {
    return (
      <Typography variant="body1" sx={{ margin: 2 }}>
        No machines found for this company.
      </Typography>
    );
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Unit</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>System</TableCell>
            <TableCell align="right">
              {machines.length > 0 && (
                <Button
                  className="system-button"
                  variant="contained"
                  size="small"
                  startIcon={<AddCircleOutline />}
                  onClick={() => handleAddSystem(machines[0].companyName)}
                >
                  Unit
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {machines.map((machine) => (
            <TableRow key={machine.id}>
              <TableCell>{machine.machineName}</TableCell>
              <TableCell>{machine.machineType}</TableCell>
              <TableCell
                style={{ fontWeight: 800, color: theme.palette.primary.main }}
              >
                {machine.system}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  color="primary"
                  name="edit"
                  onClick={() => handleEdit(machine.id, machine.companyName)}
                >
                  <EditOutlined />
                </IconButton>
                <IconButton
                  color="error"
                  name="cancel"
                  onClick={() => handleDelete(machine.id)}
                >
                  <DeleteOutlined />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default MachinesList;
