import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useFirebase } from "contexts/FirebaseContext";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";

const managerEmail = "jread@fsi.co"; // Use actual email addresses
const techEmail = "jread@foamsupplies.com";

// Placeholder for email sending logic
async function sendEmail(to, subject, body) {
  console.log(`Sending email to ${to}: ${subject} - ${body}`);
}

const FoamerDashboard = () => {
  const { user } = useFirebase();
  const navigate = useNavigate();

  return <Outlet />; // Since this component no longer directly renders content.
};

export default FoamerDashboard;
