// ScrollToTopButton.js
import React from "react";
import { IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const ScrollToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <IconButton color="secondary" onClick={handleScrollToTop}>
      <ArrowUpwardIcon />
    </IconButton>
  );
};

export default ScrollToTopButton;
