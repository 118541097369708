import React from "react";
import { TextField } from "@mui/material";


export const PhoneInput = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    let value = event.target.value;
    // Remove all non-digits
    value = value.replace(/\D/g, "");

    // Format the phone number
    value = value.substring(0, 10); // limit to 10 digits
    let formattedValue = "";
    if (value.length > 6) {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(
        6,
        10
      )}`;
    } else if (value.length > 3) {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3, 6)}`;
    } else {
      formattedValue = value;
    }

    onChange(formattedValue, event); // Pass the formatted value to the onChange callback
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      placeholder="123-456-7890"
      {...props} />
  );
};
