import React, { useEffect, useState, useMemo } from "react";
import { useFirebase } from "../contexts/FirebaseContext"; // Adjust the import path as needed
import { alpha, useTheme } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Chip,
  Select,
  MenuItem,
  IconButton,
  useMediaQuery,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import {
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { db } from "../firebase";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

const UsersList = ({ systems }) => {
  const theme = useTheme();
  const { user } = useFirebase(); // Get the current user object from the context
  const [editUserId, setEditUserId] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const queryClient = useQueryClient();

  const handleEditClick = (user) => {
    setEditUserId(user.id);
    setEditFormData({
      name: user.name,
      role: user.role,
      companyRef: user.companyRef?.id || "",
      phone: user.phone || "",
      slugGunRef: user.slugGunRef?.id || user.slugGunRef || "",
      managerRef: user.managerRef?.id || "",
    });
  };

  const handleFieldChange = (event, fieldName) => {
    if (fieldName === "phone") {
      let value = event.target.value.replace(/\D/g, "").substring(0, 10);
      let formattedValue =
        value.length > 6
          ? `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
          : value.length > 3
            ? `${value.slice(0, 3)}-${value.slice(3, 6)}`
            : value;
      setEditFormData((prev) => ({ ...prev, [fieldName]: formattedValue }));
    } else if (fieldName === "slugGunRef") {
      setEditFormData((prev) => ({
        ...prev,
        slugGunRef: event.target.value,
      }));
    } else {
      setEditFormData((prev) => ({ ...prev, [fieldName]: event.target.value }));
    }
  };

  const handleSaveClick = async (userId) => {
    // Ensure required fields are filled before proceeding
    if (!editFormData.phone) {
      alert("Phone number is required.");
      return;
    }

    if (!editFormData.role) {
      alert("Role is required.");
      return;
    }

    if (!editFormData.slugGunRef) {
      alert("A system must be assigned.");
      return;
    }

    // Handle manager selection and save it as a reference
    let managerRef = null;
    if (editFormData.managerRef) {
      managerRef = doc(db, "users", editFormData.managerRef); // Convert selected manager ID into Firestore reference
    }

    try {
      const userDoc = await getDoc(doc(db, "users", userId));
      const currentUserData = userDoc.data();

      const techRef = editFormData.techRef
        ? doc(db, "users", editFormData.techRef)
        : currentUserData.techRef || null;

      const companyRef = editFormData.companyRef
        ? doc(db, "companies", editFormData.companyRef)
        : currentUserData.companyRef || null;

      const slugGunRef = editFormData.slugGunRef
        ? doc(db, "machines", editFormData.slugGunRef)
        : currentUserData.slugGunRef || null;

      const updatedData = {
        ...currentUserData,
        ...editFormData,
        techRef: techRef ? techRef : null,
        companyRef: companyRef ? companyRef : null,
        slugGunRef: slugGunRef ? slugGunRef : null,
        managerRef: managerRef ? managerRef : null, // Save the managerRef reference
      };

      await updateDoc(doc(db, "users", userId), updatedData);
      queryClient.invalidateQueries(["usersList2"]); // Invalidate to refresh the list
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    setEditUserId(null);
  };

  const handleCancelClick = () => setEditUserId(null);

  const getRoleChipStyle = (role) => {
    switch (role) {
      case "manager":
        return {
          backgroundColor: alpha(theme.palette.warning.main, 0.1),
          color: theme.palette.warning.main,
          border: "1px solid " + theme.palette.warning.main,
        };
      case "foamer":
        return {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          color: theme.palette.primary.main,
          border: "1px solid " + theme.palette.primary.main,
        };
      default:
        return {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[600],
          border: "1px solid " + theme.palette.grey[400],
        };
    }
  };

  const {
    data: users,
    isLoading: usersLoading,
    error: usersError,
  } = useQuery({
    queryKey: ["usersList2"],
    queryFn: async () => {
      const snapshot = await getDocs(
        query(
          collection(db, "users"),
          where("companyRef", "==", user.companyRef)
        )
      );
      const allUsersData = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const userData = { ...doc.data(), id: doc.id };

          if (userData.name === "Former Employee") {
            return null;
          }

          // Fetch the manager's information based on managerRef
          let managerDetails = null;
          if (userData.managerRef) {
            const managerDoc = await getDoc(userData.managerRef);
            if (managerDoc.exists()) {
              managerDetails = managerDoc.data(); // Get manager's data (name, etc.)
            }
          }

          const slugGunDetails =
            userData.slugGunRef && typeof userData.slugGunRef === "object"
              ? await getDoc(userData.slugGunRef)
              : null;
          const slugGunSystem = slugGunDetails
            ? `${slugGunDetails.data().machineName} - ${slugGunDetails.data().system}`
            : "";

          return { ...userData, slugGunSystem, managerDetails }; // Attach the manager's details to the user data
        })
      );

      return allUsersData.filter((user) => user !== null);
    },
  });

  const confirmDeletion = (user) => {
    const confirmationMessage =
      "Are you sure you want to remove this foamer from the company? Their records will remain in the system but will be attributed to 'Former Employee.' If you do not wish to proceed with this action, press 'Cancel.' To confirm the removal, press 'OK.'";

    if (window.confirm(confirmationMessage)) {
      // Call the Cloud Function to mark the user as a former employee
      const markAsFormerEmployee = httpsCallable(
        functions,
        "markAsFormerEmployee"
      );
      markAsFormerEmployee({ userId: user.id })
        .then((result) => {
          console.log(result.data.result);
          queryClient.invalidateQueries(["usersList2"]); // Refresh the user list
        })
        .catch((error) => {
          console.error("Error marking user as former employee:", error);
        });
    } else {
      console.log("User canceled the deletion");
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "action",
        header: "",
        cell: ({ row }) =>
          row.original.role === "foamer" && (
            <IconButton
              sx={{
                color: "secondary.main",
                "&:hover": {
                  color: "error.main",
                },
              }}
              onClick={() => confirmDeletion(row.original)}
            >
              <DeleteOutlined />
            </IconButton>
          ),
      },
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => (
          <span className="bold-name">
            {row.original.firstName}{" "}
            {row.original.lastName ? row.original.lastName.charAt(0) + "." : ""}
          </span>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        cell: ({ row }) => (
          <span>
            <a
              href={`mailto:${row.original.email}`}
              style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
            >
              <MailOutlined /> {row.original.email}
            </a>
          </span>
        ),
      },
      {
        accessorKey: "phone",
        header: "Phone",
        cell: ({ row }) =>
          editUserId === row.original.id ? (
            <TextField
              value={editFormData.phone || ""}
              onChange={(e) => {
                // Handle the phone number input and format it
                let value = e.target.value.replace(/\D/g, "").substring(0, 10); // Remove all non-digits and limit to 10 digits
                let formattedValue =
                  value.length > 6
                    ? `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
                    : value.length > 3
                      ? `${value.slice(0, 3)}-${value.slice(3, 6)}`
                      : value;

                // Set the formatted value to the state
                setEditFormData((prev) => ({ ...prev, phone: formattedValue }));

                // Now validate the formatted phone number
                if (formattedValue.length !== 12) {
                  alert(
                    "Phone number format is incorrect. Please use XXX-XXX-XXXX."
                  );
                }
              }}
              placeholder="123-456-7890"
              size="small"
              fullWidth
            />
          ) : (
            <a
              href={`tel:${row.original.phone}`}
              style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
            >
              <PhoneOutlined /> {row.original.phone}
            </a>
          ),
      },
      {
        accessorKey: "role",
        header: "Role",
        cell: ({ row }) =>
          editUserId === row.original.id ? (
            <Select
              value={editFormData.role}
              onChange={(e) => handleFieldChange(e, "role")}
              size="small"
              fullWidth
            >
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="foamer">Foamer</MenuItem>
            </Select>
          ) : (
            <Chip
              label={row.original.role}
              sx={getRoleChipStyle(row.original.role)} // Pass row.original.role correctly here
              variant="outlined"
              size="small"
            />
          ),
      },
      {
        accessorKey: "manager",
        header: "Manager",
        cell: ({ row }) =>
          editUserId === row.original.id ? (
            <Select
              value={
                editFormData.managerRef || row.original.managerRef?.id || ""
              }
              onChange={(e) => handleFieldChange(e, "managerRef")}
              size="small"
              fullWidth
            >
              {users && users.length > 0 ? (
                users
                  .filter((userItem) => userItem.role === "manager") // Only managers in the list
                  .map((manager) => (
                    <MenuItem value={manager.id} key={manager.id}>
                      {manager.firstName} {manager.lastName}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem disabled>No Managers Available</MenuItem>
              )}
            </Select>
          ) : row.original.managerDetails ? (
            `${row.original.managerDetails.firstName} ${row.original.managerDetails.lastName}`
          ) : (
            <Typography color="error">Not Assigned</Typography>
          ),
      },
      {
        accessorKey: "slugGunSystem",
        header: "System",
        cell: ({ row }) =>
          editUserId === row.original.id ? (
            <Select
              value={editFormData.slugGunRef || ""}
              onChange={(e) => handleFieldChange(e, "slugGunRef")}
              size="small"
              fullWidth
            >
              {systems && systems.length > 0 ? (
                systems.map((system) => (
                  <MenuItem value={system.id} key={system.id}>
                    {system.machineName} - {system.system}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Systems Available</MenuItem>
              )}
            </Select>
          ) : (
            row.original.slugGunSystem || (
              <Typography color="error">Not Assigned</Typography>
            )
          ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        cell: ({ row }) =>
          editUserId === row.original.id ? (
            <>
              <IconButton color="error" onClick={handleCancelClick}>
                <CloseOutlined />
              </IconButton>
              <IconButton
                color="success"
                onClick={() => handleSaveClick(row.original.id)}
              >
                <SaveOutlined />
              </IconButton>
            </>
          ) : (
            <IconButton
              color="primary"
              onClick={() => handleEditClick(row.original)}
            >
              <EditOutlined />
            </IconButton>
          ),
      },
    ],
    [editUserId, editFormData, systems]
  );

  if (usersLoading) return <Typography>Loading users...</Typography>;
  if (usersError) return <Typography>Error loading users.</Typography>;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Table sx={{ display: { xs: "none", md: "table" } }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.accessorKey || column.id}>
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              {columns.map((column) => (
                <TableCell key={column.accessorKey || column.id}>
                  {column.cell
                    ? column.cell({ row: { original: user } })
                    : user[column.accessorKey]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Mobile View */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        {users.map((user) => (
          <Box
            key={user.id}
            sx={{
              padding: "10px 0",
              borderBottom: "1px solid #ccc",
              paddingLeft: "2em",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {user.firstName} {user.lastName?.charAt(0)}.
              </Typography>
              <Box>
                {editUserId === user.id ? (
                  <>
                    <IconButton color="error" onClick={handleCancelClick}>
                      <CloseOutlined />
                    </IconButton>
                    <IconButton
                      color="success"
                      onClick={() => handleSaveClick(user.id)}
                    >
                      <SaveOutlined />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      color="error"
                      onClick={() => confirmDeletion(user)}
                    >
                      <DeleteOutlined />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditClick(user)}
                    >
                      <EditOutlined />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>

            {/* Phone, Role, and System (Editable when in edit mode) */}
            <Grid container spacing={1}>
              {/* Email should not be editable */}
              <Grid item xs={12}>
                <a
                  href={`mailto:${user.email}`}
                  style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
                >
                  <MailOutlined /> {user.email}
                </a>
              </Grid>

              {/* Phone Number (60%) and Role (40%) */}
              <Grid item xs={7}>
                {editUserId === user.id ? (
                  <TextField
                    value={editFormData.phone || ""}
                    onChange={(e) => handleFieldChange(e, "phone")}
                    size="small"
                    fullWidth
                  />
                ) : (
                  <a
                    href={`tel:${user.phone}`}
                    style={{
                      color: "rgb(22, 119, 255)",
                      textDecoration: "none",
                    }}
                  >
                    <PhoneOutlined /> {user.phone}
                  </a>
                )}
              </Grid>
              <Grid item xs={5}>
                {editUserId === user.id ? (
                  <Select
                    value={editFormData.role || ""}
                    onChange={(e) => handleFieldChange(e, "role")}
                    size="small"
                    fullWidth
                  >
                    <MenuItem value="manager">Manager</MenuItem>
                    <MenuItem value="foamer">Foamer</MenuItem>
                  </Select>
                ) : (
                  <Chip
                    label={user.role}
                    sx={getRoleChipStyle(user.role)} // Apply color based on the role
                    variant="outlined"
                    size="small"
                  />
                )}
              </Grid>
              <Grid item xs={5}>
                {editUserId === user.id ? (
                  <Select
                    value={editFormData.managerRef || user.managerRef?.id || ""} // Work with managerRef
                    onChange={(e) => handleFieldChange(e, "managerRef")} // Handle change for managerRef
                    size="small"
                    fullWidth
                  >
                    <MenuItem value={user.id}>
                      Assign Myself as Manager
                    </MenuItem>
                    {users && users.length > 0 ? (
                      users
                        .filter((userItem) => userItem.role === "manager") // Only show users with the 'manager' role
                        .map((manager) => (
                          <MenuItem value={manager.id} key={manager.id}>
                            {manager.firstName} {manager.lastName}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem disabled>No Managers Available</MenuItem>
                    )}
                  </Select>
                ) : (
                  <Typography
                    variant="body2"
                    color={user.managerRef ? "inherit" : "error"}
                  >
                    {user.managerRef ? "Assigned" : "Not Assigned"}{" "}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* System info in its own line */}
            {editUserId === user.id ? (
              <Select
                value={editFormData.slugGunRef || ""}
                onChange={(e) => handleFieldChange(e, "slugGunRef")}
                size="small"
                fullWidth
              >
                {systems && systems.length > 0 ? (
                  systems.map((system) => (
                    <MenuItem value={system.id} key={system.id}>
                      {system.machineName} - {system.system}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Systems Available</MenuItem>
                )}
              </Select>
            ) : (
              <Typography sx={{ marginTop: "8px" }}>
                {user.slugGunSystem || "No system assigned"}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default UsersList;
