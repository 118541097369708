// src/hooks/useUnassignedUsers.js

import { useQuery } from "@tanstack/react-query";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ensure the path is correct
import { usersListQuery } from "../queries/usersList";

export const useUnassignedUsers = (currentUser) => {
  return useQuery({
    ...usersListQuery(currentUser),
    select: (data) =>
      data.filter((user) => {
        return user.role === "unassigned" || !user.companyRef;
      }),
  });
};
