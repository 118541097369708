// import { useContext, useState, useEffect } from 'react';
// import { signInWithEmailAndPassword, signOut } from '../contexts/firebaseOperations';
// import { onAuthStateChanged } from "firebase/auth";
// import { doc, getDoc } from "firebase/firestore";
// import { auth, db } from '../firebase';

// const useAuth = () => {
//   const [user, setUser] = useState(null);
//   const [userData, setUserData] = useState(null);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (user) => {
//       setUser(user);
//       if (user) {
//         /* const userRef = doc(db, 'users', user.uid);
//         const docSnap = await getDoc(userRef);
//         if (docSnap.exists()) {
//           setUserData(docSnap.data());
//         } else {
//           console.log('No such document!');
//         } */
//         console.log('User signed in:', user);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const firebaseEmailPasswordSignIn = async (email, password) => {
//     try {
//       // Directly call signInWithEmailAndPassword without passing 'auth'
//       await signInWithEmailAndPassword(email, password);
//     } catch (error) {
//       console.error('Error signing in:', error);
//       throw error;
//     }
//   };

//   // Assume these functions are correctly defined in your firebaseOperations or elsewhere
//   // Ensure they are updated similarly if they take 'auth' as a parameter
//   const firebaseRegister = async (email, password) => {
//     // Implementation
//   };

//   const firebaseSignOut = async () => {
//     // Implementation

//     // Clear user data
//     setUserData(null);

//     // Clear user state
//     setUser(null);

//     // Sign out
//     await signOut(auth);

//     console.log('User signed out');

//     // Redirect to login page
//     // history.push('/login');

//   };

//   const firebaseResetPassword = async (email) => {
//     // Implementation
//   };

//   return {
//     firebaseEmailPasswordSignIn,
//     firebaseRegister,
//     firebaseSignOut,
//     firebaseResetPassword,
//     user,
//     userData,
//   };
// };

// export default useAuth;

// import { useContext, useState, useEffect } from 'react';
// import { signInWithEmailAndPassword, signOut } from '../contexts/firebaseOperations';
// import { onAuthStateChanged } from "firebase/auth";
// import { doc, getDoc } from "firebase/firestore";
// import { auth, db } from '../firebase';
import { useFirebase } from 'contexts/FirebaseContext';

export default useFirebase;
