import React from "react";
import PropTypes from "prop-types";
import { Alert, Typography, Box } from "@mui/material";
import { BugFilled } from "@ant-design/icons";

const ErrorAlert = ({ errorMessage, errorDetails }) => {
  return (
    <Alert color="error" variant="border" icon={<BugFilled />} sx={{ mb: 4 }}>
      <Typography variant="h4" sx={{ color: "error.dark" }}>
        Off Ratio.
      </Typography>
      {errorMessage}
      <Box sx={{ mt: 2, color: "#5a0711" }}>
        <Typography>
          {errorDetails.ratio ? (
            <>
              <span style={{ marginRight: "8px" }}>✅</span>
              Ratio Passed.
            </>
          ) : (
            <>
              <span style={{ marginRight: "8px" }}>❌</span>
              Ratio Failed.
            </>
          )}
        </Typography>
        <Typography>
          {errorDetails.throughputDrop ? (
            <>
              <span style={{ marginRight: "8px" }}>✅</span>
              Throughput Drop Passed.
            </>
          ) : (
            <>
              <span style={{ marginRight: "8px" }}>❌</span>
              Throughput Drop Failed.
            </>
          )}
        </Typography>
        <Typography>
          {errorDetails.ppmOutput ? (
            <>
              <span style={{ marginRight: "8px" }}>✅</span>
              PPM Output Passed.
            </>
          ) : (
            <>
              <span style={{ marginRight: "8px" }}>❌</span>
              PPM Output Failed.
            </>
          )}
        </Typography>
        <Typography>
          {errorDetails.visualCheck ? (
            <>
              <span style={{ marginRight: "8px" }}>✅</span>
              Visual Check Passed.
            </>
          ) : (
            <>
              <span style={{ marginRight: "8px" }}>❌</span>
              Visual Check Failed - Make sure you are using a clean mix tube and
              your mixing air is sufficient.
            </>
          )}
        </Typography>
      </Box>
    </Alert>
  );
};

ErrorAlert.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  errorDetails: PropTypes.shape({
    ratio: PropTypes.bool.isRequired,
    throughputDrop: PropTypes.bool.isRequired,
    ppmOutput: PropTypes.bool.isRequired,
    visualCheck: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ErrorAlert;
