import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from "../firebase";
import {
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  FormHelperText,
  Divider,
  Box,
  CircularProgress,
  InputLabel,
  Stack,
  Select,
  MenuItem
} from '@mui/material';
import IconBell from '@mui/icons-material/NotificationsActive';
import IconMessage from '@mui/icons-material/Message';
import IconCampaign from '@mui/icons-material/Campaign';
import MainCard from "components/MainCard";
import AnimateButton from "components/@extended/AnimateButton";

// Assume IconSelector is a separate component or define it here
const IconSelector = () => {
  const [icon, setIcon] = useState('');

  const handleChange = (event) => {
    setIcon(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="icon-select-label">Which Icon?</InputLabel>
      <Select
        labelId="icon-select-label"
        id="icon-select"
        value={icon}
        label="Icon"
        onChange={handleChange}
        displayEmpty
      >
        <MenuItem value=""> </MenuItem>
        <MenuItem value="bell">
          <Grid container alignItems="center" spacing={1}>
            <Grid item><IconBell /></Grid>
            <Grid item>Bell</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="message">
          <Grid container alignItems="center" spacing={1}>
            <Grid item><IconMessage /></Grid>
            <Grid item>Message</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="campaign">
          <Grid container alignItems="center" spacing={1}>
            <Grid item><IconCampaign /></Grid>
            <Grid item>Campaign</Grid>
          </Grid>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

// Validation schema
const validationSchema = yup.object({
  post: yup.string().required('Post is required.'),
  icon: yup.string().required('Icon selection is required.')
});

const Messages = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formik = useFormik({
    initialValues: {
      post: '',
      icon: ''
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const docData = {
        ...values,
        timestamp: serverTimestamp(),
      };

      try {
        await addDoc(collection(db, "notifications"), docData);
        console.log("Notification submitted successfully");
        setIsSubmitted(true); // Set isSubmitted to true to show the success message
        resetForm();
      } catch (error) {
        console.error("Error writing document to Firestore", error);
      }
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ mb: 4 }}>
            Post a Notification
          </Typography>
        </Grid>
        {isSubmitted ? (
          <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
            <MainCard raised sx={{ p: 2, backgroundColor: "success.darker", color: "white" }}>
              <Typography variant="h1" sx={{ mb: 1 }}>
                Success!
              </Typography>
              <Typography variant="h2" sx={{ mb: 4 }}>
                Notification Successfully Submitted!
              </Typography>
              <Typography variant="h4">
                The notification should be in the list of notifications.
              </Typography>
              <Button onClick={() => setIsSubmitted(false)} sx={{ mt: 2 }}>
                Send Another Notification
              </Button>
            </MainCard>
          </Grid>
        ) : (
          <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
            <MainCard raised sx={{ p: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel sx={{ textAlign: { xs: "left", sm: "right" }, fontWeight: 900 }}>
                    Post:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={8}>
                  <TextField
                    fullWidth
                    id="post"
                    name="post"
                    value={formik.values.post}
                    onChange={formik.handleChange}
                    error={formik.touched.post && Boolean(formik.errors.post)}
                    helperText={formik.touched.post && formik.errors.post}
                  />
                </Grid>
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel sx={{ textAlign: { xs: "left", sm: "right" }, fontWeight: 900 }}>
                    Icon:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={8}>
                  <IconSelector />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                  <AnimateButton>
                    <Button variant="contained" type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <CircularProgress size={24} /> : "Submit"}
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </MainCard>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default Messages;
