import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseContext";
import { rolePermissions } from "../config/roles";

const ProtectedRoute = ({ children, requiredRank }) => {
  const { user } = useFirebase();
  const location = useLocation();
  const userRank = rolePermissions[user?.role] || 1;

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredRank && userRank < requiredRank) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
