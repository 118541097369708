// material-ui
import { Stack, Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";

// project import
import MainCard from "components/MainCard";
import { db } from "../../../../firebase";
import { useFirebase } from "../../../../contexts/FirebaseContext";

const NavCard = () => {
  const { userData } = useFirebase();
  const [companyCode, setCompanyCode] = useState("Loading...");

  // Fetch company code if the user has a companyRef
  useEffect(() => {
    const fetchCompanyCode = async () => {
      if (userData?.companyRef) {
        const companyDoc = await getDoc(userData.companyRef);
        if (companyDoc.exists()) {
          setCompanyCode(companyDoc.data().companyCode || "N/A");
        } else {
          setCompanyCode("No Company Code");
        }
      } else {
        setCompanyCode("No Company Code");
      }
    };

    fetchCompanyCode();
  }, [userData]);

  return (
    <>
      {userData?.role === "manager" && (
        <MainCard
          sx={{
            bgcolor: "#fff5d7", // Warm yellow background
            border: "1px solid", // Add a solid border
            borderColor: "#ffd454", // Custom border color
            color: "#ebb000", // Custom text color
            m: 3,
            display: { xs: "block", sm: "block", md: "block", lg: "block" }, // Ensure visibility on all screen sizes
            flexDirection: "column", // Stack the items
            alignItems: "center", // Center content
          }}
        >
          <Stack alignItems="center" spacing={2.5}>
            {/* Company Code */}
            <Stack alignItems="center" sx={{ mt: 2 }}>
              <Typography
                variant="h5"
                sx={{ color: "#ebb000", fontWeight: "bold" }}
              >
                Company Code
              </Typography>
              <Typography
                variant="body2"
                align="center"
                sx={{ color: "#ebb000", mt: 1 }}
              >
                Share this code with new hires <br /> to sign up.
              </Typography>
              <Typography variant="h3" sx={{ color: "#ebb000", mt: 1 }}>
                {companyCode}
              </Typography>
            </Stack>
          </Stack>
        </MainCard>
      )}
    </>
  );
};

export default NavCard;
