// action - state management
import { LOADING, REGISTER, LOGIN, LOGOUT, SET_INITIALIZED } from './actions';

// initial state
export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  loading: true
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        loading: false,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      // console.log("LOGIN action received", user);
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        loading: false,
        user
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        loading: false,
        user: null
      };
    }
    case SET_INITIALIZED: {
      // Handle the SET_INITIALIZED action to explicitly set the initialization flag
      return {
        ...state,
        loading: false,
        isInitialized: action.payload // Assuming the payload is a boolean representing the initialized state
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
