// utils/calculateRatios.js
export const calculateRatios = (polyWeight, isoWeight, durationBagShot) => {
  const poundsPerSecondStepOne = 60 / durationBagShot;
  const poundsPerSecondStepTwo = polyWeight * poundsPerSecondStepOne;
  const poundsPerMinute = poundsPerSecondStepTwo / 453.592;
  const poundsPerSecond = poundsPerMinute / 60;

  // Calculate the ratio of ISO Weight to PolyWeight
  const ratio = polyWeight / isoWeight;

  return {
    poundsPerSecond: poundsPerSecond.toFixed(2),
    poundsPerMinute: poundsPerMinute.toFixed(2),
    ratio: ratio.toFixed(2), // Return the calculated ratio, formatted to two decimal places
  };
};
