// IssueFixForm.js
import React, { useState } from "react";
import {
  Typography,
  FormControl,
  Grid,
  Button,
  TextField,
  Divider,
  Stack,
} from "@mui/material";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import MainCard from "components/MainCard";

const IssueFixForm = ({ docId, navigate }) => {
  const [issue, setIssue] = useState("");
  const [fix, setFix] = useState("");
  const [otherIssue, setOtherIssue] = useState("");
  const [otherFix, setOtherFix] = useState("");

  const handleSubmit = async () => {
    try {
      const docRef = doc(db, "formEntries", docId);
      await updateDoc(docRef, {
        issue: issue === "other" ? otherIssue : issue,
        fix: fix === "other" ? otherFix : fix,
      });
      if (navigate) navigate("/success-message");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <MainCard
      variant="outlined"
      sx={{
        backgroundColor: "#f0f4f8",
        color: "#333",
        padding: "10px",
        mb: 3,
      }}
    >
      <Typography variant="h3" component="div" sx={{ mb: 1 }}>
        Issue & Fix:
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Select the issue you encountered and the fix you applied from the
        options below. If you choose "Other," provide additional details in the
        text field that will appear.
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3} lg={4}>
          <Typography
            sx={{
              textAlign: { xs: "left", sm: "right" },
              fontWeight: 900,
            }}
          >
            Issue:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} lg={6}>
          <FormControl fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  variant={
                    issue === "High Flow Rate" ? "contained" : "outlined"
                  }
                  onClick={() => setIssue("High Flow Rate")}
                  fullWidth
                >
                  High Flow Rate
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={
                    issue === "Temp out of Range" ? "contained" : "outlined"
                  }
                  onClick={() => setIssue("Temp out of Range")}
                  fullWidth
                >
                  Temp out of Range
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={
                    issue === "Wrong Timer Setting" ? "contained" : "outlined"
                  }
                  onClick={() => setIssue("Wrong Timer Setting")}
                  fullWidth
                >
                  Wrong Timer Setting
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={
                    issue === "Bad Strat Connection" ? "contained" : "outlined"
                  }
                  onClick={() => setIssue("Bad Strat Connection")}
                  fullWidth
                >
                  Bad Strat Connection
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={
                    issue === "Incorrect Nitrogen Pressure"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => setIssue("Incorrect Nitrogen Pressure")}
                  fullWidth
                >
                  Incorrect Nitrogen Pressure
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={
                    issue === "Throughput too low" ? "contained" : "outlined"
                  }
                  onClick={() => setIssue("Throughput too low")}
                  fullWidth
                >
                  Throughput too low
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={
                    issue === "Poor Mix Quality" ? "contained" : "outlined"
                  }
                  onClick={() => setIssue("Poor Mix Quality")}
                  fullWidth
                >
                  Poor Mix Quality
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={issue === "other" ? "contained" : "outlined"}
                  onClick={() => setIssue("other")}
                  fullWidth
                >
                  Other
                </Button>
              </Grid>
            </Grid>
            {issue === "other" && (
              <TextField
                fullWidth
                id="otherIssue"
                name="otherIssue"
                placeholder="Please specify the issue"
                value={otherIssue}
                onChange={(e) => setOtherIssue(e.target.value)}
                sx={{ mt: 2 }}
              />
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3} lg={4}>
            <Typography
              sx={{
                textAlign: { xs: "left", sm: "right" },
                fontWeight: 900,
              }}
            >
              Fix:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} lg={6}>
            <FormControl fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    variant={
                      fix === "Flow Controller Adjusted / Replaced"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() =>
                      setFix("Flow Controller Adjusted / Replaced")
                    }
                    fullWidth
                  >
                    Flow Controller Adjusted / Replaced
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={
                      fix === "Drilled / Replaced the Block"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setFix("Drilled / Replaced the Block")}
                    fullWidth
                  >
                    Drilled / Replaced the Block
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={
                      fix === "Brought to Correct Temp"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setFix("Brought to Correct Temp")}
                    fullWidth
                  >
                    Brought to Correct Temp
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={
                      fix === "Corrected Timer Setting"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setFix("Corrected Timer Setting")}
                    fullWidth
                  >
                    Corrected Timer Setting
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={
                      fix === "Tested & Reseated Strat"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setFix("Tested & Reseated Strat")}
                    fullWidth
                  >
                    Tested & Reseated Strat
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={
                      fix === "Inspected & Cleaned Air Purge Assembly"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() =>
                      setFix("Inspected & Cleaned Air Purge Assembly")
                    }
                    fullWidth
                  >
                    Inspected & Cleaned Air Purge Assembly
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={
                      fix === "Changed Mixed Tube" ? "contained" : "outlined"
                    }
                    onClick={() => setFix("Changed Mixed Tube")}
                    fullWidth
                  >
                    Changed Mixed Tube
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={fix === "other" ? "contained" : "outlined"}
                    onClick={() => setFix("other")}
                    fullWidth
                  >
                    Other
                  </Button>
                </Grid>
              </Grid>
              {fix === "other" && (
                <TextField
                  fullWidth
                  id="otherFix"
                  name="otherFix"
                  placeholder="Please specify the fix"
                  value={otherFix}
                  onChange={(e) => setOtherFix(e.target.value)}
                  sx={{ mt: 2 }}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      </Grid>
    </MainCard>
  );
};

export default IssueFixForm;
