// JumpToSection.js
import React from "react";
import { Typography, Link, Box } from "@mui/material";

const JumpToSection = ({ sections }) => {
  return (
    <Box
      mb={5}
      sx={{
        backgroundColor: "aliceblue",
        color: "cornflowerblue",
        width: "fit-content",
        padding: "9px 18px",
        borderRadius: "12px",
        marginBottom: "0px",
        marginTop: "12px",
        marginBottom: "36px",
      }}
    >
      <Typography variant="h6" sx={{}}>
        Jump to:{" "}
        {sections.map((section, index) => (
          <span key={section.name}>
            <Link
              href="#"
              onClick={section.onClick}
              sx={{
                color: "cornflowerblue", // Adjust as needed
                textDecoration: "none", // Removes underline
                margin: "0 8px",
                fontWeight: "bold",
                "&:hover": {
                  textDecoration: "underline", // Underline on hover
                },
                "&:focus": {
                  outline: "none", // Removes focus outline
                },
              }}
            >
              {section.name}
            </Link>
            {index < sections.length - 1 && " | "}
          </span>
        ))}
      </Typography>
    </Box>
  );
};

export default JumpToSection;
