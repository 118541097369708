import React, { useEffect } from "react";

const PreventBackNavigation = () => {
  useEffect(() => {
    window.history.forward(1);

    // Ensure that the back navigation is prevented whenever the component is rendered
    const handlePopState = () => {
      window.history.forward(1);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return null;
};

export default PreventBackNavigation;
