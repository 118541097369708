import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useAuth from "hooks/useAuth";
import useScriptRef from "hooks/useScriptRef";
import IconButton from "components/@extended/IconButton";
import AnimateButton from "components/@extended/AnimateButton";
import { strengthColor, strengthIndicator } from "utils/password-strength";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { db } from "../../../firebase";
import {
  setDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";

const AuthRegister = () => {
  const { firebaseRegister } = useAuth();
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();

  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [companyError, setCompanyError] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, ""); // Remove all non-digit characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match digits to the pattern
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`; // Return formatted phone number
    }
    return value; // Return the raw value if it doesn't match the format
  };

  useEffect(() => {
    changePassword("");
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          phone: "",
          companyCode: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().max(255).required("First Name is required"),
          lastname: Yup.string().max(255).required("Last Name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
          phone: Yup.string()
            .required("Phone number is required")
            .matches(
              /^\d{3}-\d{3}-\d{4}$/,
              "Phone number must be in the format XXX-XXX-XXXX"
            ),
          companyCode: Yup.string().max(5).required("Company Code is required"), // Validation for company code
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            console.log("Trying firebase register");

            // Step 1: Simplify to just checking the company code
            const q = query(
              collection(db, "companies"),
              where("companyCode", "==", values.companyCode.slice(0, 4)) // Strip the last digit for lookup
            );
            console.log("Checking company existence...");
            const companySnapshot = await getDocs(q);
            if (companySnapshot.empty) {
              console.error("Company not found for code:", values.companyCode);
              setCompanyError("Invalid Company Code");
              setSubmitting(false);
              return;
            } else {
              console.log("Company found");
            }

            const companyDoc = companySnapshot.docs[0]; // Get the first matching company
            const companyRef = companyDoc.ref; // Company reference
            const companyData = companyDoc.data(); // Company data
            console.log("Company found:", companyData);

            // Step 2: Automatically find the manager for this company
            console.log("Checking for manager...");
            const managerQuery = query(
              collection(db, "users"),
              where("role", "==", "manager"),
              where("companyRef", "==", companyRef)
            );
            const managerSnapshot = await getDocs(managerQuery);

            let managerRef = null;
            if (!managerSnapshot.empty) {
              const managerDoc = managerSnapshot.docs[0]; // Get the first manager
              managerRef = managerDoc.ref; // Manager reference
              console.log("Manager found:", managerDoc.data());
            } else {
              console.log("No manager found for this company.");
            }

            // Step 3: Automatically assign a system to the new user
            console.log("Checking for systems...");
            const systemsQuery = query(
              collection(db, "machines"),
              where("companyRef", "==", companyRef)
            );
            const systemsSnapshot = await getDocs(systemsQuery);

            let assignedslugGunRef = null;
            if (!systemsSnapshot.empty) {
              const systemDoc = systemsSnapshot.docs[0]; // Get the first available system
              assignedslugGunRef = systemDoc.ref; // System reference
              console.log("System found:", systemDoc.data());
            } else {
              console.log("No system found for this company.");
            }

            // Step 4: Directly use the techRef from the company document
            console.log("Checking for tech...");

            // Declare techRef outside the if block to make it available throughout
            let techRef = null;

            if (companyData.techRef) {
              const techDoc = await getDoc(companyData.techRef);

              if (techDoc.exists()) {
                const techData = techDoc.data(); // Fetch the tech's data
                techRef = techDoc.ref; // Get tech reference for storing in the new user
                console.log("Tech found:", techData); // Debugging output
              } else {
                console.log("No tech found for this company.");
              }
            } else {
              console.log("No techRef found in the company data.");
            }

            // Step 5: Determine user role based on last digit of company code
            let userRole = "foamer"; // Default role
            const lastCharacter = values.companyCode.slice(-1); // Get the last character
            if (lastCharacter === "1") {
              userRole = "manager"; // Set to manager if the last character is "1"
            }

            // Step 6: Proceed with Firebase user registration
            console.log("Registering user...");
            const userCredential = await firebaseRegister(
              values.email,
              values.password
            );

            if (userCredential && userCredential.user) {
              const user = userCredential.user;

              // Combine first and last name into one field called "name"
              const fullName = `${values.firstname} ${values.lastname}`;

              // Step 7: Store user data in Firestore, including companyRef, managerRef, techRef, and slugGunRef
              console.log("Writing user data to Firestore...");
              await setDoc(doc(db, "users", user.uid), {
                firstName: values.firstname,
                lastName: values.lastname,
                name: fullName,
                email: values.email,
                role: userRole, // Set role based on companyCode
                companyRef: companyRef, // Reference to the user's company
                managerRef: managerRef, // Reference to manager (if found)
                techRef: techRef || null, // Reference to tech (if found)
                slugGunRef: assignedslugGunRef || null, // Reference to system (if found)
                phone: values.phone, // Save phone number
              });

              console.log("User document successfully written!");

              // Redirect to dashboard after successful registration
              window.location.href = "/dashboard/apps/profiles/user/personal";
            } else {
              throw new Error(
                "User registration failed, no user data received."
              );
            }
          } catch (err) {
            console.error("Error during registration:", err);

            if (scriptedRef.current) {
              let errorMessage = "An error occurred during registration.";

              if (err.code === "auth/email-already-in-use") {
                errorMessage =
                  "This email is already in use. Please use another email.";
              } else if (err.code === "auth/invalid-email") {
                errorMessage = "The email address is not valid.";
              } else if (err.code === "auth/weak-password") {
                errorMessage = "The password is too weak.";
              }

              setErrors({ submit: errorMessage });
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="firstname-signup">
                    First Name*
                  </InputLabel>
                  <OutlinedInput
                    id="firstname-login"
                    type="firstname"
                    value={values.firstname}
                    name="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="John"
                    fullWidth
                    error={Boolean(touched.firstname && errors.firstname)}
                  />
                </Stack>
                {touched.firstname && errors.firstname && (
                  <FormHelperText error id="helper-text-firstname-signup">
                    {errors.firstname}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="lastname-signup">Last Name*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.lastname && errors.lastname)}
                    id="lastname-signup"
                    type="lastname"
                    value={values.lastname}
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Doe"
                    inputProps={{}}
                  />
                </Stack>
                {touched.lastname && errors.lastname && (
                  <FormHelperText error id="helper-text-lastname-signup">
                    {errors.lastname}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="demo@company.com"
                    inputProps={{}}
                  />
                </Stack>
                {touched.email && errors.email && (
                  <FormHelperText error id="helper-text-email-signup">
                    {errors.email}
                  </FormHelperText>
                )}
              </Grid>

              {/* Phone Number Field */}
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="phone-signup">Phone Number*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    id="phone-signup"
                    type="text"
                    value={values.phone}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const formattedPhone = formatPhoneNumber(e.target.value);
                      setFieldValue("phone", formattedPhone); // Update Formik's phone field with the formatted value
                    }}
                    placeholder="Enter your phone number"
                    inputProps={{}}
                  />
                </Stack>
                {touched.phone && errors.phone && (
                  <FormHelperText error id="helper-text-phone-signup">
                    {errors.phone}
                  </FormHelperText>
                )}
              </Grid>

              {/* Company Code Field */}
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="companyCode-signup">
                    Company Code*
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.companyCode && errors.companyCode)}
                    id="companyCode-signup"
                    type="text"
                    value={values.companyCode}
                    name="companyCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Company Code"
                    inputProps={{}}
                  />
                </Stack>
                {touched.companyCode && errors.companyCode && (
                  <FormHelperText error id="helper-text-companyCode-signup">
                    {errors.companyCode}
                  </FormHelperText>
                )}
                {companyError && (
                  <FormHelperText error id="helper-text-companyError">
                    {companyError}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-signup">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="password-signup"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      changePassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="secondary"
                        >
                          {showPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="******"
                    inputProps={{}}
                  />
                </Stack>
                {touched.password && errors.password && (
                  <FormHelperText error id="helper-text-password-signup">
                    {errors.password}
                  </FormHelperText>
                )}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box
                        sx={{
                          bgcolor: level?.color,
                          width: 85,
                          height: 8,
                          borderRadius: "7px",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontSize="0.75rem">
                        {level?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  By Signing up, you agree to our &nbsp;
                  <Link variant="subtitle2" component={RouterLink} to="#">
                    Terms of Service
                  </Link>
                  &nbsp; and &nbsp;
                  <Link variant="subtitle2" component={RouterLink} to="#">
                    Privacy Policy
                  </Link>
                </Typography>
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create Account
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthRegister;
