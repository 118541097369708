import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import MainCard from "components/MainCard";
import IssueFixForm from "./IssueFixForm"; // Import the IssueFixForm
import { useFirebase } from "../contexts/FirebaseContext";
import { getContactData } from "../utils/dataFetchers";
import { useNavigate, useLocation } from "react-router-dom";
import "../themes/CallPage.css";

const CallPage = () => {
  const { user } = useFirebase();
  const location = useLocation();
  const navigate = useNavigate();
  const { docId } = location.state || {}; // Get docId from location state

  useEffect(() => {
    document.body.classList.add("hide-header");
    return () => {
      document.body.classList.remove("hide-header");
    };
  }, []);

  const {
    data: managerContact,
    isLoading: isManagerContactLoading,
    isError: isManagerContactError,
  } = useQuery({
    queryKey: ["userManager", user?.uid],
    queryFn: () => getContactData(user?.managerRef),
    enabled: !!user?.managerRef,
  });

  const {
    data: techContact,
    isLoading: isTechContactLoading,
    isError: isTechContactError,
  } = useQuery({
    queryKey: ["userTech", user?.uid],
    queryFn: () => getContactData(user?.techRef),
    enabled: !!user?.techRef,
  });

  if (isManagerContactLoading || isTechContactLoading) {
    return <div>Loading contacts...</div>;
  }

  if (isManagerContactError || !managerContact) {
    return <div>Error loading manager contact.</div>;
  }

  if (isTechContactError || !techContact) {
    return <div>Error loading tech contact.</div>;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <Typography variant="h2" sx={{ mt: 4, mb: 2, textAlign: "center" }}>
          Assistance Needed
        </Typography>
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              sx={{ mt: 4, mb: 1, ml: 2 }}
              color="text.secondary"
            >
              Manager
            </Typography>
            <Typography variant="h2" gutterBottom sx={{ ml: 2, mb: 1 }}>
              {managerContact.name}
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ ml: 2, mb: 4 }}
            >
              <Button
                variant="outlined"
                size="large"
                color="primary"
                startIcon={<PhoneOutlined />}
                href={`tel:${managerContact.phone}`}
              >
                {managerContact.phone}
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                startIcon={<MailOutlined />}
                href={`mailto:${managerContact.email}`}
              >
                {managerContact.email}
              </Button>
            </Stack>

            <Divider sx={{ mt: 4, mb: 2 }} />

            <Typography
              variant="h6"
              sx={{ mt: 4, mb: 1, ml: 2 }}
              color="text.secondary"
            >
              Tech Support
            </Typography>
            <Typography variant="h2" gutterBottom sx={{ ml: 2, mb: 1 }}>
              {techContact.name}
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ ml: 2, mb: 4 }}
            >
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                startIcon={<PhoneOutlined />}
                href={`tel:${techContact.phone}`}
              >
                {techContact.phone}
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                startIcon={<MailOutlined />}
                href={`mailto:${techContact.email}`}
              >
                {techContact.email}
              </Button>
            </Stack>
          </CardContent>
        </Card>
        <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
          Contact your manager or tech support for further assistance.
        </Typography>
        <Box mt={4}>
          <IssueFixForm docId={docId} navigate={navigate} />{" "}
          {/* Use the IssueFixForm */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CallPage;
