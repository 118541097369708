import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";

// project-import
import { ThemeMode } from "config";

// Import your logos
import fsilogo from "assets/images/auth/iqc.svg"; // Normal logo
import fsilogoDark from "assets/images/auth/iqc-letters.svg"; // Optionally, a different logo for dark mode if you have one

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {
  const theme = useTheme();

  // Decide which logo to use based on the theme mode or reverse prop
  const logoToUse =
    theme.palette.mode === ThemeMode.DARK || reverse ? fsilogoDark : fsilogo;

  return (
    // Render your logo based on the theme
    <img
      src={logoToUse}
      alt="IntelliQC by FSI"
      width="48"
      style={{ opacity: 0.85 }}
    />
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool,
};

export default LogoMain;
