// dataFetchers.js
import { getDoc } from "firebase/firestore";

export const fetchSlugGunSystemData = async (slugGunRef) => {
  if (slugGunRef) {
    const slugGunDoc = await getDoc(slugGunRef);
    if (slugGunDoc.exists()) {
      return slugGunDoc.data();
    } else {
      throw new Error("Slug gun document not found");
    }
  } else {
    throw new Error("Slug gun reference not found");
  }
};

export const getContactData = async (contactRef) => {
  if (contactRef) {
    const contactSnap = await getDoc(contactRef);
    if (contactSnap.exists()) {
      return contactSnap.data();
    } else {
      throw new Error("Contact document not found");
    }
  } else {
    throw new Error("Contact reference not found");
  }
};
