import { auth } from '../firebase';
import { setDoc } from 'firebase/firestore';

// First, import the required function from the Firebase auth module at the top of your firebaseOperations.js
import {
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword, 
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword, 
  signOut as firebaseSignOut, 
  sendPasswordResetEmail as firebaseSendPasswordResetEmail, 
  onAuthStateChanged 
} from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

// Correct the signInWithEmailAndPassword function
export const signInWithEmailAndPassword = async (email, password) => {
  return await firebaseSignInWithEmailAndPassword(auth, email, password);
};

// Do similarly for other auth operations, adjusting to the Modular SDK's method signatures
export const createUserWithEmailAndPassword = async (email, password) => {
  const userCredential = await firebaseCreateUserWithEmailAndPassword(auth, email, password);
  const { user } = userCredential;
  
  const userRef = doc(getFirestore(), `users/${user.uid}`);
  
  try {
      await setDoc(userRef, {
          email: user.email,
          role: 'unassigned',
          // Additional user data can be set here
      });
      console.log("Document successfully written!");
  } catch (error) {
      console.error("Error writing document: ", error);
  }
  console.log("User credential:", userCredential);
  return userCredential;
};

export const signOut = async () => {
  return await firebaseSignOut(auth);
};

export const sendPasswordResetEmail = async (email) => {
  return await firebaseSendPasswordResetEmail(auth, email);
};

export const authStateChangeListener = (callback) => {
  return onAuthStateChanged(auth, async (authUser) => {
    if (authUser) {
      const userRef = doc(getFirestore(), `users/${authUser.uid}`);
      getDoc(userRef).then(docSnap => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          callback(true, userData); // Call the callback with user data
        } else {
          console.log("Firebase Context - No such document!");
          callback(false); // Handle no user data found
        }
      }).catch(error => {
        console.error("Error fetching user data:", error);
        callback(false); // Handle error fetching user data
      });
    } else {
      callback(false); // Handle no user signed in
    }
  });
};
