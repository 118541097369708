import React, { useMemo, useCallback } from "react";
import { useFirebase } from "../contexts/FirebaseContext";
import { alpha, useTheme } from "@mui/material/styles";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  doc,
  updateDoc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { useUnassignedUsers } from "../hooks/useUnassignedUsers";
import { db } from "../firebase";

import "../assets/css/styles.css";

const UnassignedUsersList = ({ setUsers, companyRef }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useFirebase();
  const queryClient = useQueryClient();

  // Fetch unassigned users
  const {
    data: unassignedUsers,
    isLoading: isLoadingUnassignedUsers,
    error: unassignedUsersError,
  } = useUnassignedUsers(user);

  // Function to associate the user with a company
  const handleAssociateWithCompany = useCallback(
    async (userId) => {
      if (!userId) return;

      try {
        // Get the Firestore reference for the company
        const companyRefDoc = doc(db, "companies", companyRef.id);

        console.log(
          "companyRef being used in system query:",
          companyRefDoc.path
        ); // Ensure you are logging the full reference

        // Fetch the list of systems for the company using the full company reference
        const systemsQuery = query(
          collection(db, "machines"),
          where("companyRef", "==", companyRefDoc) // Make sure to use the full Firestore document reference
        );

        const systemsSnapshot = await getDocs(systemsQuery);

        if (systemsSnapshot.empty) {
          console.log("No systems found for this company.");
          return;
        }

        const systems = systemsSnapshot.docs.map((doc) => {
          const systemData = doc.data();
          console.log("Fetched system data:", systemData); // Log fetched system data
          return doc.id;
        });

        if (systems.length === 0) {
          console.log("No systems found for this company.");
        } else {
          console.log("Systems found for this company:", systems);
        }

        // Prepare the updated data for the user
        let updatedData = {
          companyRef: companyRefDoc, // Set the full company reference
          role: "foamer", // Automatically set role to 'foamer'
          techRef: doc(db, "users", user.uid), // Assign the current logged-in user (tech)
        };

        // If exactly one system is found, associate it
        if (systems.length === 1) {
          updatedData.slugGunRef = doc(db, "machines", systems[0]);
        }

        // Update the user document with all the changes
        await updateDoc(doc(db, "users", userId), updatedData);
        console.log("User associated with company and updated successfully!");

        // Invalidate and refresh user list
        queryClient.invalidateQueries(["usersList"]);
      } catch (error) {
        console.error("Error associating user with company:", error);
      }
    },
    [companyRef, user.uid, queryClient]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        cell: (props) => {
          const firstName = props.row.original.firstName || "";
          const lastName = props.row.original.lastName
            ? props.row.original.lastName.charAt(0) + "."
            : "";
          return (
            <span>
              {firstName} {lastName}
            </span>
          );
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        cell: (props) =>
          props.getValue() ? (
            <a
              href={`mailto:${props.getValue()}`}
              style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
            >
              {props.getValue()}
            </a>
          ) : (
            ""
          ),
      },
      {
        accessorKey: "associate",
        header: "Add to Company", // No header needed for button
        cell: (props) => (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleAssociateWithCompany(props.row.original.id);
            }}
            aria-label="Associate with company"
          >
            <PlusCircleOutlined />
          </IconButton>
        ),
      },
    ],
    [handleAssociateWithCompany]
  );

  const table = useReactTable({
    data: unassignedUsers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (isLoadingUnassignedUsers) {
    return <div>Loading...</div>;
  }

  if (unassignedUsersError) {
    const errorMessage = unassignedUsersError?.message;
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Table stickyHeader>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell
                  key={header.id}
                  className={
                    header.id === "phone" || header.id === "role"
                      ? "hide-on-mobile"
                      : "default-class"
                  }
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  className={
                    cell.column.id === "phone" || cell.column.id === "role"
                      ? "hide-on-mobile"
                      : "default-class"
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default UnassignedUsersList;
