// src/pages/TutorialLibrary.js
import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { WarningFilled } from "@ant-design/icons";
import MainCard from "components/MainCard";

const TutorialLibrary = () => (
  <Grid
    container
    spacing={6}
    justifyContent="center"
    alignItems="center"
    sx={{ mt: 3, mb: 12 }}
  >
    <Grid item xs={12} md={12}>
      <Typography variant="h1" component="h1" align="center" gutterBottom>
        Tutorial Library
      </Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <Container maxWidth="lg" sx={{ px: { xs: 0, sm: 2 } }}>
        <MainCard
          title={
            <Typography
              variant="h5"
              style={{ fontSize: "1.4rem", fontWeight: "bold" }}
            >
              3-Step Setup
            </Typography>
          }
          sx={{ mb: 2 }}
        >
          <Typography variant="body">
            <h3>1. Set up a Company, Equipment, and System</h3>
            Create a company by clicking{" "}
            <Link to="/dashboard/setup-gun">setup a Gun</Link>. This allows you
            to build the company, the system and the equipment that will be
            used. Once this has been done the company will show up in your{" "}
            <Link to="/dashboard/tech">Tech Dashboard</Link>.
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Typography variant="body">
              On the company page you will see the Company Name, the Machine &
              System List, the Users at the company, and then the Unassigned
              Users List. Your first item is to scroll to the unassigned users
              list and click the action button (pencil icon). Now you can edit
              the user.
              <h3>2. Unassigned Users List Section</h3>
              <ol>
                <li>Change their role to either foamer or manager</li>
                <li>Click the + button to assign yourself as the tech</li>
                <li>
                  In the dropdown for System, select the System this user will
                  be using
                </li>
                <li>Click the + button to assign them to this company</li>
                <li>Click the green Send button to save</li>
                <li>
                  The user should now be moved up to the "Users at the Company"
                  section
                </li>
              </ol>
              <hr />
              <h3>3. Users at the Company Section</h3>
              <ol>
                <li>Click the action button (pencil icon)</li>
                <li>
                  Make sure there is a manager at the company, there must be one
                  manager in this company list
                </li>
                <li>
                  If the user is a "foamer" - ensure that you are their tech
                </li>
                <li>
                  If there is no tech assigned, click the + button to assign
                  yourself
                </li>
                <li>Select their manager from the dropdown menu</li>
                <li>Click the Send button to save</li>
                <li>
                  The user should now have both a Tech and a Manager assigned to
                  them
                </li>
              </ol>
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Alert severity="info">
              You and the Manager will both have the ability to edit the user's
              name and phone number as well. In the future the user will be able
              to edit their own profile.
            </Alert>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Alert color="warning" icon={<WarningFilled />}>
              In the "Users at the Company" section, every foamer MUST have an
              assigned Tech and Manager. This ensures clear communication if
              they go off-ratio.
            </Alert>
          </Box>
        </MainCard>

        <MainCard
          title={
            <Typography
              variant="h5"
              style={{ fontSize: "1.4rem", fontWeight: "bold" }}
            >
              Setting up a new Company, Gun, or System
            </Typography>
          }
          sx={{ mb: 2 }}
        >
          <Typography variant="body">
            To create a company, you need to{" "}
            <Link to="/dashboard/setup-gun">setup a Gun</Link>. This allows you
            to build the company, the system and the equipment that will be
            used.
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Button
              component={Link}
              to="/dashboard/setup-company"
              variant="contained"
              color="primary"
            >
              Click here to setup a company
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Alert color="warning" icon={<WarningFilled />}>
              Setting up multiple guns at a company - Currently you will need to
              contact <a href="tel:+19042081707">Josh Read</a>. This feature
              will be coming soon.
            </Alert>
          </Box>
        </MainCard>

        <MainCard
          title={
            <Typography
              variant="h5"
              style={{ fontSize: "1.4rem", fontWeight: "bold" }}
            >
              Sending Messages
            </Typography>
          }
          sx={{ mb: 2 }}
        >
          <Box sx={{ mt: 2 }}>
            <Alert color="warning" icon={<WarningFilled />}>
              This is a future feature that will allow you to create a message
              that will show up in the bell. The goal is for it to also notify
              the user's phone. Eventually I hope to have it automated so it can
              send messages based on certain events or if the user hasn't logged
              a Ratio Check in a certain number of days, etc.
              <br />
              <br />
              It would also be beneficial to have a way to send a message to a
              company, a manager, or a specific user.
            </Alert>
          </Box>
        </MainCard>
      </Container>
    </Grid>
  </Grid>
);

export default TutorialLibrary;
