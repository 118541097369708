import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const UnassignedDashboard = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleGoToProfile = () => {
    navigate("/dashboard/apps/profiles/user/personal"); // Navigate to the correct profile page route
  };

  return (
    <div>
      <h1>Account Activation Pending</h1>
      <p className="spaced-paragraph">
        Thank you for signing up. Your account is currently pending activation
        by your technical team or manager.
      </p>
      <h3>While you wait</h3>
      <p>Please visit your profile and make sure everything is up to date.</p>
      <Button variant="contained" onClick={handleGoToProfile}>
        Go to Profile
      </Button>
    </div>
  );
};

export default UnassignedDashboard;
