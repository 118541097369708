import React, { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { useFirebase } from "../contexts/FirebaseContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const NavUser = () => {
  const { user, userData, userDataLoading: loading } = useFirebase();
  const [companyName, setCompanyName] = useState("Loading...");
  const [companyCode, setCompanyCode] = useState("Loading...");

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (userData?.companyRef) {
        const companyDoc = await getDoc(userData.companyRef);
        if (companyDoc.exists()) {
          const companyData = companyDoc.data();
          setCompanyName(companyData.companyName || "No Company");
          setCompanyCode(companyData.companyCode || "No Code");
        } else {
          setCompanyName("No Company");
          setCompanyCode("No Code");
        }
      } else {
        setCompanyName("No Company");
        setCompanyCode("No Code");
      }
    };

    fetchCompanyData();
  }, [userData]);

  const secondaryText = loading
    ? "Loading ..."
    : `${companyName} (${companyCode}) - ${userData?.role || "No Role"}`;

  return (
    <Box sx={{ p: 1.25 }}>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemText
            primary={
              userData?.firstName && userData?.lastName
                ? `${userData.firstName} ${userData.lastName.charAt(0).toUpperCase()}.`
                : "No Name"
            }
            secondary={secondaryText}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default NavUser;
