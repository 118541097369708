// material-ui
import { useTheme } from "@mui/material/styles";

// Import your logo
import fsilogo from "assets/images/auth/iqc.svg"; // Ensure the path is correct

// project import
import { ThemeMode } from "config";

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  // If you have a different logo for dark mode, you can import it and use it similarly
  // For demonstration, using fsilogo for both modes
  return (
    // Use the img tag to display your logo
    <img
      src={fsilogo}
      alt="IntelliQC by FSI"
      width="100"
      style={{ opacity: theme.palette.mode === ThemeMode.DARK ? 0.35 : 0.35 }}
    />
  );
};

export default LogoIcon;
