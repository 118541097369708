import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  TextField,
  Button,
  Grid,
  Alert,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { calculateRatios } from "../utils/calculateRatios";
import EntryCard from "./MainCardDailyReport"; // Import your EntryCard component

const SearchFormEntries = ({ companyName }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [includeEndDate, setIncludeEndDate] = useState(false); // To toggle the end date picker
  const [results, setResults] = useState([]);
  const [searchExecuted, setSearchExecuted] = useState(false);

  useEffect(() => {
    console.log("Received companyName in SearchFormEntries:", companyName);
  }, [companyName]);

  const handleStartDateChange = (date) => {
    console.log("Selected Start Date:", date);
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    console.log("Selected End Date:", date);
    setEndDate(date);
  };

  const handleCheckboxChange = (event) => {
    setIncludeEndDate(event.target.checked);
    if (!event.target.checked) {
      setEndDate(null); // Reset the end date if the checkbox is unchecked
    }
  };

  const handleSearch = async () => {
    if (!companyName) {
      console.error("Company ID not set.");
      return;
    }

    if (!startDate) {
      console.error("Start date must be selected.");
      return;
    }

    console.log("Searching for form entries for company:", companyName);
    console.log("Selected Start Date:", startDate);
    if (includeEndDate) {
      console.log("Selected End Date:", endDate);
    }

    const formEntriesRef = collection(db, "formEntries");

    const startOfDay = new Date(startDate);
    startOfDay.setHours(0, 0, 0, 0);
    console.log("Start of Day:", startOfDay);

    let endOfDay;
    if (includeEndDate && endDate) {
      endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);
    } else {
      endOfDay = new Date(startDate);
      endOfDay.setHours(23, 59, 59, 999);
    }
    console.log("End of Day:", endOfDay);

    const q = query(
      formEntriesRef,
      where("timestamp", ">=", startOfDay),
      where("timestamp", "<=", endOfDay),
      where("company", "==", companyName)
    );

    try {
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());
      console.log("Query Results:", data);

      setResults(data);
      setSearchExecuted(true);

      if (data.length === 0) {
        console.log("No results found for the selected date range.");
      }
    } catch (error) {
      console.error("Error fetching form entries:", error);
    }
  };

  return (
    <Box>
      <h3>Search Form Entries</h3>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} md={6}>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            placeholderText="Select Start Date"
            customInput={
              <TextField
                label="Start Date"
                variant="outlined"
                fullWidth
                autoComplete="off"
              />
            }
          />
        </Grid>

        {!includeEndDate && (
          <Grid item xs={6} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeEndDate}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Search within a date range"
            />
          </Grid>
        )}

        {includeEndDate && (
          <>
            <Grid item xs={6} md={6}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                placeholderText="Select End Date"
                customInput={
                  <TextField
                    label="End Date"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                  />
                }
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeEndDate}
                    onChange={handleCheckboxChange}
                    color="primary"
                    className="customSearchFormcheckbox"
                  />
                }
                label="Search within a date range"
                className={`customSearchFormLabel ${
                  includeEndDate ? "checked" : ""
                }`}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={!companyName || !startDate}
            fullWidth
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {searchExecuted && results.length === 0 ? (
        <Alert variant="outlined" severity="warning" sx={{ mt: 4 }}>
          No results found
        </Alert>
      ) : (
        <Grid container spacing={3} sx={{ mt: 4 }}>
          {results.map((entry, index) => {
            const { poundsPerSecond, poundsPerMinute, ratio } = calculateRatios(
              entry.PolyWeight,
              entry.ISOWeight,
              entry.durationBagShot
            );

            const chipColor = entry.isOffRatio ? "error" : "success";

            return (
              <EntryCard
                key={index}
                entry={entry}
                poundsPerSecond={poundsPerSecond}
                poundsPerMinute={poundsPerMinute}
                ratio={ratio}
                chipColor={chipColor}
              />
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default SearchFormEntries;
