import {
    DashboardOutlined
} from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const formsTables = {
  id: 'group-forms-tables-admin',
  title: "Admin",
  icon: icons.DashboardOutlined,
  type: 'group',
  children: [
    {
      id: 'validation',
      title: 'Admin Dashboard',
      type: 'item',
      url: '/dashboard/admin',
      icon: icons.DashboardOutlined
    }
  ]
};

export default formsTables;
