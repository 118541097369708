import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ThemeCustomization from "themes";
import Locales from "./components/Locales";
import RTLLayout from "./components/RTLLayout";
import ScrollTop from "./components/ScrollTop";
import Snackbar from "./components/@extended/Snackbar";
import Notistack from "./components/third-party/Notistack";
import { FirebaseProvider as AuthProvider } from "./contexts/FirebaseContext";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import RoleBasedRedirect from "./components/RoleBasedRedirect";
import ProtectedRoute from "./components/ProtectedRoutes";

// Define your route components
import AdminDashboard from "./pages/dashboard/admin";
import TechDashboard from "./pages/dashboard/tech";
import CompanyPage from "./components/CompanyPage";
import ManagerDashboard from "./pages/dashboard/manager";
import FoamerDashboard from "./pages/dashboard/foamer";
import FirstFormComponent from "./components/FirstFormComponent";
import SecondFormComponent from "./components/SecondFormComponent";
import ThirdFormComponent from "./components/ThirdFormComponent";
import EditUserProfile from "./components/EditUserProfile";
import SetupGun from "./components/SetupGun";
import Messages from "./components/Messages";
import SuccessPage from "./components/SuccessPage";
import CallPage from "./components/CallPage";
import UnassignedDashboard from "./pages/dashboard/unassigned";
import Login from "./pages/auth/login";
import DashboardLayout from "layout/Dashboard";
import { rolePermissions } from "./config/roles";
import Unauthorized from "./pages/maintenance/unauthorized";
import DefaultDashboard from "./pages/dashboard/default";
import ForgotPassword from "./pages/auth/forgot-password";
import Register from "./pages/auth/register";
import Error404 from "./pages/maintenance/404";
import TutorialLibrary from "./pages/TutorialLibrary";
import TestPasswordReset from "./TestPasswordReset";

const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <Notistack>
                  <Router>
                    <RoleBasedRedirect />
                    <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route path="/register" element={<Register />} />
                      <Route
                        path="/test-password-reset"
                        element={<TestPasswordReset />}
                      />
                      <Route path="/dashboard" element={<DashboardLayout />}>
                        {/* Wrap each Route with ProtectedRoute and pass the requiredRank */}
                        <Route
                          path="apps/profiles/user/personal"
                          element={
                            <ProtectedRoute>
                              <EditUserProfile />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="admin"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["admin"]}
                            >
                              <AdminDashboard />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="tech"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["tech"]}
                            >
                              <TechDashboard />
                            </ProtectedRoute>
                          }
                        ></Route>
                        <Route
                          path="tech/company/:id/:slug"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["tech"]}
                            >
                              <CompanyPage />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="setup-gun"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["tech"]}
                            >
                              <SetupGun />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="tutorial-library"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["tech"]}
                            >
                              <TutorialLibrary />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="messages"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["tech"]}
                            >
                              <Messages />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="manager"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["manager"]}
                            >
                              <ManagerDashboard />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="foamer"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["foamer"]}
                            >
                              {" "}
                              <FoamerDashboard />{" "}
                            </ProtectedRoute>
                          }
                        >
                          <Route
                            index
                            element={
                              <ProtectedRoute
                                requiredRank={rolePermissions["foamer"]}
                              >
                                <FirstFormComponent />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="second-form"
                            element={
                              <ProtectedRoute
                                requiredRank={rolePermissions["foamer"]}
                              >
                                <SecondFormComponent />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="third-form"
                            element={
                              <ProtectedRoute
                                requiredRank={rolePermissions["foamer"]}
                              >
                                <ThirdFormComponent />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="success"
                            element={
                              <ProtectedRoute
                                requiredRank={rolePermissions["foamer"]}
                              >
                                <SuccessPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="call"
                            element={
                              <ProtectedRoute
                                requiredRank={rolePermissions["foamer"]}
                              >
                                <CallPage />
                              </ProtectedRoute>
                            }
                          />
                        </Route>
                        <Route
                          path="unassigned"
                          element={
                            <ProtectedRoute
                              requiredRank={rolePermissions["unassigned"]}
                            >
                              <UnassignedDashboard />
                            </ProtectedRoute>
                          }
                        />
                      </Route>
                      <Route path="/unauthorized" element={<Unauthorized />} />
                      <Route
                        path="/dashboard/default"
                        element={<DefaultDashboard />}
                      />
                      {/* Catch-all route for 404 Page Not Found */}
                      <Route path="*" element={<Error404 />} />
                    </Routes>
                    <Snackbar />
                  </Router>
                </Notistack>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              </QueryClientProvider>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
