// ratioUtils.js

import { doc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
let errorDetails = {};

export const calculateRatioData = (
  values,
  slugGunSystemData,
  durationBagShot
) => {
  const { ISOWeight, PolyWeight, bagWeight } = values;

  const sumAB = parseFloat(ISOWeight) + parseFloat(PolyWeight);
  const ratioalpha = parseFloat(PolyWeight) / parseFloat(ISOWeight);
  const ratio = ratioalpha * 100;
  const percentage = parseFloat(bagWeight) / sumAB;
  const throughputDropalpha = percentage * 100;
  const throughputDrop = 100 - throughputDropalpha;

  const poundsPerSecondStepOne = 60 / durationBagShot;
  const poundsPerSecondStepTwo = bagWeight * poundsPerSecondStepOne;
  const poundsPerMinute = poundsPerSecondStepTwo / 453.592;
  const poundsPerSecond = poundsPerMinute / 60;

  const outputLowerLimit = parseFloat(slugGunSystemData?.outputLowerLimit || 0);
  const outputUpperLimit = parseFloat(slugGunSystemData?.outputUpperLimit || 0);
  const ratioLowerLimit = parseFloat(slugGunSystemData?.ratioLowerLimit || 0);
  const ratioUpperLimit = parseFloat(slugGunSystemData?.ratioUpperLimit || 0);

  const isOffRatio =
    ratio < ratioLowerLimit ||
    ratio > ratioUpperLimit ||
    poundsPerMinute < outputLowerLimit ||
    poundsPerMinute > outputUpperLimit ||
    throughputDrop > 8;
  /*
  console.log("sumAB:", sumAB);
  console.log("ratioalpha:", ratioalpha);
  console.log("ratio:", ratio);
  console.log("percentage:", percentage);
  console.log("throughputDropalpha:", throughputDropalpha);
  console.log("throughputDrop:", throughputDrop);
  console.log("poundsPerSecondStepOne:", poundsPerSecondStepOne);
  console.log("poundsPerSecondStepTwo:", poundsPerSecondStepTwo);
  console.log("poundsPerMinute:", poundsPerMinute);
  console.log("poundsPerSecond:", poundsPerSecond);
  console.log("isOffRatio:", isOffRatio);
*/
  errorDetails = {
    ratio: !(ratio < ratioLowerLimit || ratio > ratioUpperLimit),
    throughputDrop: !(throughputDrop > 8),
    ppmOutput: !(
      poundsPerMinute < outputLowerLimit || poundsPerMinute > outputUpperLimit
    ),
    visualCheck: values.visualCheck === "yes",
  };

  localStorage.setItem("errorDetails", JSON.stringify(errorDetails));

  return {
    sumAB,
    ratioalpha,
    ratio,
    percentage,
    throughputDropalpha,
    throughputDrop,
    poundsPerSecondStepOne,
    poundsPerSecondStepTwo,
    poundsPerMinute,
    poundsPerSecond,
    isOffRatio,
  };
};

export const getErrorDetails = () => errorDetails;

export const prepareDocData = (
  values,
  user,
  currentUser,
  slugGunSystemData,
  ratioData,
  formType
) => {
  const userRef = doc(db, "users", currentUser.uid);

  return {
    ...values,
    userName: user.name || "No user name assigned",
    userId: currentUser.uid,
    userRef,
    phoneNumber: user.phone || "No phone number",
    gunNumber: slugGunSystemData?.machineName || "No slugGun assigned",
    company: slugGunSystemData?.companyName || "No company assigned",
    techRef: user.techRef || "No tech assigned",
    managerRef: user.managerRef || "No manager assigned",
    timestamp: serverTimestamp(),
    durationBagShot:
      slugGunSystemData?.durationBagShot || "No durationBagShot assigned",
    output: slugGunSystemData?.output || "No output assigned",
    outputLowerLimit:
      slugGunSystemData?.outputLowerLimit || "No outputLowerLimit assigned",
    outputUpperLimit:
      slugGunSystemData?.outputUpperLimit || "No outputUpperLimit assigned",
    ratioLowerLimit:
      slugGunSystemData?.ratioLowerLimit || "No ratioLowerLimit assigned",
    ratioUpperLimit:
      slugGunSystemData?.ratioUpperLimit || "No ratioUpperLimit assigned",
    temperatureLowerLimit:
      slugGunSystemData?.temperatureLowerLimit ||
      "No temperatureLowerLimit assigned",
    temperatureUpperLimit:
      slugGunSystemData?.temperatureUpperLimit ||
      "No temperatureUpperLimit assigned",
    formType,
    isOffRatio: ratioData.isOffRatio,
    poundsPerMinute: parseFloat(ratioData.poundsPerMinute).toFixed(4) || "N/A",
    poundsPerSecond: parseFloat(ratioData.poundsPerSecond).toFixed(6) || "N/A",
    throughputDrop: parseFloat(ratioData.throughputDrop).toFixed(2),
    visualCheck: values.visualCheck || "N/A",
  };
};
