import React, { useEffect } from "react";
import { auth } from "./firebase"; // Import the correctly initialized Firebase Auth object
import { sendPasswordResetEmail } from "firebase/auth"; // Import the function directly from firebase/auth

const TestPasswordReset = () => {
  // Function to test password reset email
  const testPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email); // Use the correct auth instance as the first argument
      console.log("Password reset email sent!");
    } catch (error) {
      console.error("Password reset error:", error.message);
    }
  };

  // Use useEffect to call the test function once when the component loads
  useEffect(() => {
    testPasswordReset("profsitech@gmail.com"); // Replace with the email you are testing
  }, []);

  return (
    <div>
      <h2>Testing Password Reset</h2>
    </div>
  );
};

export default TestPasswordReset;
