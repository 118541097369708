import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../../contexts/FirebaseContext'; // Adjust the import path as needed

// Material-UI and other imports remain the same
import { Button, Grid, Stack, Typography } from '@mui/material';

function DefaultDashboard() {
  const { logout } = useFirebase(); // Assuming your context provides a logout method
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    try {
      await logout(); // Attempt to log out
      navigate('/login'); // Navigate to login page on successful logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', pt: 1.5, pb: 1, overflow: 'hidden' }}
      >
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h1">Hmmm.</Typography>
            <Typography color="textSecondary" align="center" sx={{ width: { xs: '90%', sm: '90%' } }}>
              Let's try again.
            </Typography>
            {/* Updated Button for Logout */}
            <Button onClick={handleLogout} variant="contained">
              Logout
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default DefaultDashboard;
