import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Link, Stack, Typography, Box } from "@mui/material";
import ScrollToTopButton from "components/ScrollToTopButton";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        direction={{ xs: "column", sm: "row" }} // Column on small screens, row on larger
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }} // Align left on small screens
        sx={{ p: "24px 16px 0px", mt: { xs: 4, sm: 6 } }}
        spacing={{ xs: 2, sm: 0 }} // Add space between elements on small screens
      >
        {/* Links */}
        <Stack
          spacing={1.5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ order: { xs: 1, sm: 0 } }} // Stack links above copyright on small screens
        >
          <Link
            component={RouterLink}
            to="https://fsi.co/about"
            target="_blank"
            variant="caption"
            color="textPrimary"
          >
            About us
          </Link>
          <Link
            component={RouterLink}
            to="https://fsi.co/privacy-policy/"
            target="_blank"
            variant="caption"
            color="textPrimary"
          >
            Privacy
          </Link>
          <Link
            component={RouterLink}
            to="https://fsi.co/terms-and-conditions/"
            target="_blank"
            variant="caption"
            color="textPrimary"
          >
            Terms
          </Link>
        </Stack>

        {/* Copyright Line */}
        <Typography variant="caption" sx={{ order: { xs: 2, sm: 0 } }}>
          &copy; {currentYear} FSI - All rights reserved
        </Typography>
      </Stack>

      {/* ScrollToTopButton */}
      <Box sx={{ position: "absolute", right: "16px", bottom: "24px" }}>
        <ScrollToTopButton />
      </Box>
    </Box>
  );
};

export default Footer;
