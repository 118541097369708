// material-ui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import fsilogo from "assets/images/auth/iqc-letters.svg";

// project import
import { ThemeDirection, ThemeMode } from "config";

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        filter: "blur(0px)",
        zIndex: -1,
        left: "5%",
        top: "29%",
        transform: "translateY(-50%)",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        transform:
          theme.direction === ThemeDirection.RTL ? "rotate(180deg)" : "inherit",
      }}
    >
      {/* Replace the existing SVG with an img tag for your logo */}
      <img
        src={fsilogo}
        alt="IntelliQC by FSI"
        style={{ width: "80%", height: "auto", opacity: 0.35 }}
      />
    </Box>
  );
};

export default AuthBackground;
