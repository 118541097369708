import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseContext";

const RoleBasedRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userDataLoading, userLoading } = useFirebase();

  useEffect(() => {
    // Paths accessible without authentication
    const publicPaths = ["/login", "/forgot-password", "/register"];

    // Paths specific to form submissions, which do not require redirects
    const formPaths = [
      "/dashboard/foamer",
      "/dashboard/foamer/first-form",
      "/dashboard/foamer/second-form",
      "/dashboard/foamer/third-form",
      "/dashboard/foamer/success",
      "/dashboard/foamer/call",
    ];

    // Additional paths that should be accessible based on role, but not redirected from
    const additionalValidPaths = [
      "/dashboard/setup-gun", // This is a shared path between admin and tech roles
      "/dashboard/messages", // This is a shared path between admin and tech roles
      "/dashboard/tutorial-library", // This is a shared path between admin and tech roles
      "/dashboard/apps/profiles/user/personal", // This is a shared path between all roles
    ];

    // Helper function to determine if the path is public
    const pathIsPublic = publicPaths.includes(location.pathname);
    // Helper function to determine if the path is for form submissions
    const isFormPath =
      formPaths.includes(location.pathname) ||
      additionalValidPaths.includes(location.pathname);

    // Determines the default path based on the user's role
    const getRolePath = (role) => {
      const rolePath = {
        admin: "/dashboard/admin",
        tech: "/dashboard/tech",
        manager: "/dashboard/manager",
        foamer: "/dashboard/foamer",
        unassigned: "/dashboard/unassigned",
      };
      return rolePath[role] || "/";
    };

    if (isFormPath || pathIsPublic) {
      // Bypass redirection if on a form-specific or public path
      return;
    }

    if (!user && !userLoading && !userDataLoading) {
      // Redirect to login if no user is logged in and the path isn't public
      navigate("/login");
      return;
    }

    if (user && !pathIsPublic && !isFormPath) {
      // Redirect to the role-based default path if not already there
      const redirectPath = getRolePath(user.role);
      if (!location.pathname.startsWith(redirectPath)) {
        navigate(redirectPath);
      }
    }
  }, [navigate, user, userDataLoading, userLoading, location.pathname]);

  return null; // This component does not render anything; it only handles redirects
};

export default RoleBasedRedirect;
