import React, { useEffect, useState } from "react";
import {
  Alert,
  List,
  ListItemButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import MainCard from "components/MainCard";
import { collection, query, where, getDocs } from "firebase/firestore";
import { BugFilled } from "@ant-design/icons";
import { db } from "../../../firebase";

const OffRatioEvents = ({ companyName }) => {
  const [events, setEvents] = useState([]);
  const [issueGroups, setIssueGroups] = useState({});
  const [maxCount, setMaxCount] = useState(1); // Store the max count for consistent y-axis scaling

  useEffect(() => {
    const fetchEvents = async () => {
      if (companyName) {
        console.log("Fetching events for company:", companyName);
        try {
          const eventsCollection = collection(db, "formEntries");

          // Fetch all events for the company
          const allEventsQuery = query(
            eventsCollection,
            where("company", "==", companyName)
          );
          const allEventsSnapshot = await getDocs(allEventsQuery);
          const allEvents = allEventsSnapshot.docs
            .map((doc) => doc.data())
            .filter((event) => event.issue && event.fix); // Filter out events with no issue or fix

          // Group events by issue type and count occurrences
          const groupedIssues = {};
          allEvents.forEach((event) => {
            if (!groupedIssues[event.issue]) {
              groupedIssues[event.issue] = [];
            }
            groupedIssues[event.issue].push(event);
          });

          // Determine the max count of events for consistent y-axis scaling
          const maxEventCount = Math.max(
            ...Object.values(groupedIssues).map((events) => events.length)
          );

          const latestEvents = Object.entries(groupedIssues).map(
            ([issue, events]) => {
              const latestEvent = events.sort(
                (a, b) => b.timestamp.seconds - a.timestamp.seconds
              )[0];
              return { latestEvent, count: events.length };
            }
          );

          console.log("Fetched events:", latestEvents);

          setEvents(latestEvents);
          setIssueGroups(groupedIssues);
          setMaxCount(maxEventCount); // Update the max count
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      } else {
        console.warn("companyName is not defined");
      }
    };

    fetchEvents();
  }, [companyName]);

  const generateTrendGrid = (events) => {
    const grid = Array(24).fill(0); // Create a grid of 2 rows and 12 columns with event count

    events.forEach((event) => {
      const date = new Date(event.timestamp.seconds * 1000);
      const month = date.getMonth();
      const year = date.getFullYear();
      const currentYear = new Date().getFullYear();

      if (year === currentYear) {
        grid[month] += 1; // Increment the count for the corresponding month of the current year
      } else if (year === currentYear - 1) {
        grid[12 + month] += 1; // Increment the count for the corresponding month of the previous year
      }
    });

    return grid;
  };

  const renderGrid = (grid) => (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={0.0}
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", sm: "420px" }, // Adjust maxWidth as needed
        }}
      >
        {grid.slice(0, 12).map((count, index) => (
          <Box
            key={`current-${index}`}
            bgcolor={
              count > 1
                ? "#f04134"
                : count === 1
                ? "rgba(240, 65, 52, 0.7)"
                : "transparent"
            }
            border={1}
            borderColor="grey.300"
            width="100%"
            height="30px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {count > 1 && (
              <Typography
                variant="caption"
                color="white"
                sx={{ fontWeight: 900 }}
              >
                {count}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={0.0}
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", sm: "420px" }, // Adjust maxWidth as needed
        }}
      >
        {grid.slice(12).map((count, index) => (
          <Box
            key={`previous-${index}`}
            bgcolor={count > 0 ? "#f04134" : "transparent"}
            border={1}
            borderColor="grey.300"
            width="100%"
            height="30px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {count > 1 && (
              <Typography variant="caption" color="white">
                {count}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </>
  );

  const renderMonthLabels = () => (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={0.0}
      sx={{
        width: "100%",
        maxWidth: { xs: "100%", sm: "420px" }, // Adjust maxWidth as needed
      }}
    >
      {["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"].map(
        (label, index) => (
          <Typography
            key={index}
            variant="body2"
            align="center"
            color="grey.300"
            sx={{ display: "block" }}
          >
            {label}
          </Typography>
        )
      )}
    </Box>
  );

  return (
    <MainCard sx={{ mt: 2 }} content={false}>
      <List sx={{ p: 0, "& .MuiListItemButton-root": { py: 2 } }}>
        {events.length === 0 ? (
          <Alert color="error" icon={<BugFilled />}>
            No Events Found
          </Alert>
        ) : (
          events.map((eventData, index) => (
            <ListItemButton key={index} divider>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={2}>
                  <Typography variant="h6" color="textSecondary">
                    {new Date(
                      eventData.latestEvent.timestamp.seconds * 1000
                    ).toLocaleString()}
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    {eventData.latestEvent.gunNumber} -{" "}
                    {eventData.latestEvent.userName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="h4">
                    {eventData.latestEvent.issue}
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    {eventData.count}{" "}
                    {eventData.count === 1 ? "event" : "events"} in past year
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {renderGrid(
                    generateTrendGrid(issueGroups[eventData.latestEvent.issue])
                  )}
                  {renderMonthLabels()}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5" color="textSecondary">
                    {eventData.latestEvent.fix}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemButton>
          ))
        )}
      </List>
    </MainCard>
  );
};

export default OffRatioEvents;
