//@ts-check
import { queryOptions } from "@tanstack/react-query";
import {
  query,
  where,
  collection,
  doc,
  getDoc,
  getDocs,
  DocumentReference,
} from "firebase/firestore";
import { db } from "../firebase";

/**
 * @typedef {{ companyRef?: DocumentReference, email: string, name?: string, id?: string, phone?: string, role: string, tech?: DocumentReference, slugGunSystem?: DocumentReference }} User
 */

async function safeGetDocData(ref) {
  try {
    if (ref && typeof ref === "object" && ref._methodName !== "deleteField") {
      const docSnap = await getDoc(ref);
      return docSnap.exists() ? docSnap.data() : null;
    }
    return null;
  } catch (e) {
    console.error("Error fetching document data:", e);
    return null;
  }
}

export const populateUserRefs = async (doc) => {
  const userData = {
    .../** @type {User} */ (doc.data?.() || doc),
    id: doc.id,
    uid: doc.id,
  };

  return {
    ...userData,
    tech: await safeGetDocData(userData.techRef),
    manager: await safeGetDocData(userData.managerRef),
    slugGun: await safeGetDocData(userData.slugGunRef), // Add this to each user's object
    company: await safeGetDocData(userData.companyRef),
  };
};

/**
 * @param {any} currentUser
 */
export const usersListQuery = (currentUser) => {
  return queryOptions({
    queryKey: ["usersList"],
    queryFn: async () => {
      try {
        const currentUserId = currentUser.uid;
        const currentUserRole = currentUser.role;
        const currentUserCompanyRef = currentUser.companyRef;

        let q = null;

        if (currentUserRole === "admin") {
          q = collection(db, "users");
        } else if (currentUserRole === "manager") {
          q = query(
            collection(db, "users"),
            where("companyRef", "==", currentUserCompanyRef),
            where("role", "in", ["unassigned", "foamer"])
          );
        } else if (currentUserRole === "tech") {
          q = query(
            collection(db, "users"),
            where("role", "in", ["manager", "unassigned", "foamer"])
          );
        } else if (
          currentUserRole === "foamer" ||
          currentUserRole === "unassigned"
        ) {
          q = query(collection(db, "users"), where("uid", "==", currentUserId));
        }

        const usersSnap = q ? await getDocs(q) : null;
        const currentUserDoc = await getDoc(doc(db, "users/" + currentUserId));
        const allUsersData = await Promise.all(
          [...(usersSnap?.docs || []), currentUserDoc].map(populateUserRefs)
        );

        return allUsersData;
      } catch (e) {
        console.error("Error fetching users list:", e);
        return [];
      }
    },
  });
};
