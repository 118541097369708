// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../firebase";
// import { queryOptions } from "@tanstack/react-query";

// export const systemQueryOptions = () =>
//   queryOptions({
//     queryKey: ["systemQuery"],
//     queryFn: async () => (await getDocs(collection(db, "machines"))).docs,
//   });
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

export const systemQueryOptions = (companyRef) => ({
  queryKey: ["systemQuery", companyRef?.id],
  queryFn: async () => {
    if (!companyRef) throw new Error("No companyRef provided");

    const q = query(
      collection(db, "systems"),
      where("companyRef", "==", companyRef)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  },
});
