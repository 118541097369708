import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import MainCard from "components/MainCard";
import IssueFixForm from "./IssueFixForm"; // Import the IssueFixForm
import { calculateRatios } from "../utils/calculateRatios"; // Import the calculation function

const ResultCard = ({
  bagWeight,
  isoWeight,
  durationBagShot,
  docId,
  showIssueFix,
}) => {
  const { poundsPerSecond, poundsPerMinute } = calculateRatios(
    bagWeight,
    isoWeight, // Now passing isoWeight
    durationBagShot
  );

  return (
    <>
      {showIssueFix && <IssueFixForm docId={docId} />}{" "}
      <MainCard
        variant="outlined"
        sx={{
          backgroundColor: "#edf4eb",
          color: "#33831c",
          padding: "10px",
        }}
      >
        <Typography variant="h1" sx={{ mb: 1 }}>
          Success!
        </Typography>
        <Typography variant="h2" sx={{ mb: 4, color: "#86ac7b" }}>
          You're on ratio!
        </Typography>
        <Typography variant="h4" sx={{ mb: 5, color: "#86ac7b" }}>
          You can begin in confidence that you are on ratio according to the
          data entered. Thank you for your attention to detail.
        </Typography>

        <Typography variant="h1" sx={{ mb: 1 }}>
          {poundsPerSecond}
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          Pounds Per Second
        </Typography>

        <Typography variant="h1" sx={{ mb: 1 }}>
          {poundsPerMinute}
        </Typography>
        <Typography variant="body1">Pounds Per Minute</Typography>
      </MainCard>
    </>
  );
};

export default ResultCard;
