import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn, userLoading, userDataLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn && !userLoading && !userDataLoading) {
      // Redirect to the login page, and replace the current entry in the history stack
      // Also pass in the current location so you can redirect the user back after logging in
      navigate('/login', {
        state: { from: location.pathname },
        replace: true
      });
    }
  }, [isLoggedIn, userLoading, userDataLoading, navigate, location]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
