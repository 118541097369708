import {
  FormOutlined
} from '@ant-design/icons';

// icons
const icons = {
  FormOutlined
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const formsTables = {
  id: 'group-forms-tables-foamer',
  title: "QC Procedure",
  icon: icons.FormOutlined,
  type: 'group',
  children: [
    {
      id: 'validation',
      title: 'QC Procedure',
      type: 'item',
      url: '/dashboard/foamer',
      icon: icons.FormOutlined
    }
  ]
};

export default formsTables;
