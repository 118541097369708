import React from "react";
import { Grid, Typography, Chip } from "@mui/material";
import MainCard from "components/MainCard";

const EntryCard = ({
  entry,
  poundsPerSecond,
  poundsPerMinute,
  ratio,
  chipColor,
}) => {
  // Format the date without seconds and include "at"
  const formattedDate = new Date(entry.timestamp.seconds * 1000)
    .toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
    .replace(",", " at");

  return (
    <Grid item xs={12} md={4}>
      <MainCard
        title={formattedDate}
        border={false}
        boxShadow
        sx={{ height: "100%", padding: "16px" }}
        secondary={
          <Chip
            variant="outlined"
            label={`Ratio: ${ratio}`}
            color={chipColor}
            size="large"
            sx={{ fontWeight: "bold" }}
          />
        }
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body1">
              <strong>User:</strong> {entry.userName || "N/A"} at{" "}
              {entry.company || "N/A"}
            </Typography>

            <Typography variant="body1">
              <strong>Gun Number:</strong> {entry.gunNumber || "N/A"}
            </Typography>

            <Typography variant="body1">
              <strong>PolyWeight:</strong> {entry.PolyWeight || "N/A"}
            </Typography>

            <Typography variant="body1">
              <strong>ISO Weight:</strong> {entry.ISOWeight || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Duration Bag Shot:</strong>{" "}
              {entry.durationBagShot || "N/A"}
            </Typography>

            <Typography variant="body1">
              <strong>Pounds Per Second:</strong>{" "}
              <Chip label={poundsPerSecond} color={chipColor} size="small" />
            </Typography>

            <Typography variant="body1">
              <strong>Pounds Per Minute:</strong>{" "}
              <Chip label={poundsPerMinute} color={chipColor} size="small" />
            </Typography>

            <Typography variant="body1">
              <strong>Visual Check:</strong>{" "}
              {entry.visualCheck === "yes"
                ? "Pass"
                : entry.visualCheck === "no"
                ? "Fail"
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
};

export default EntryCard;
