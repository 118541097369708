// project import
import admin from './admin-dashboard';
import tech from './tech-dashboard';
import manager from './manager-dashboard';
import foamer from './foamer';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [ admin, tech, manager, foamer ]
};

export default menuItems;
