import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Adjust this import path as necessary

const useFetchUserData = (userUid) => {
  const [userData, setUserData] = useState({
    company: "Loading...",
    role: "Loading...",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!userUid) {
      // console.error("User ID is undefined or not set.");
      // setError({ message: "User ID is undefined or not set." });
      setLoading(false);
      return;
    }
    // const fetchUserData = async () => {
    //   const docRef = doc(db, 'users', userUid);
    //   try {
    //     const docSnap = await getDoc(docRef);
    //     if (docSnap.exists()) {
    //       const data = docSnap.data();

    //       setUserData(data);
    //       console.log("Role:", data.role); // Log the user's role here

    //     } else {
    //       console.log('Inside useFetchUserData.js - useFetchUserData - No such document!');
    //       setError('Inside useFetchUserData.js - useFetchUserData - No such document!');
    //     }
    //   } catch (err) {
    //     console.error('Error fetching document:', err);
    //     setError('Error fetching document.');
    //   }
    //   setLoading(false);
    // };
    const fetchUserData = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", userUid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // console.log("User Data:", userData);
          if (userData.companyRef) {
            const companyDoc = await getDoc(userData.companyRef);
            if (companyDoc.exists()) {
              setUserData({
                ...userData,
                company: companyDoc.data().name,
              });
            } else {
              setUserData(userData);
            }
          } else {
            setUserData(userData);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userUid]);

  return { userData, loading, error };
};

export default useFetchUserData;
