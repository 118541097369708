import React, { useEffect, useState, useRef, useMemo, useReducer } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, where, query } from "firebase/firestore";
import { useFirebase } from "contexts/FirebaseContext";
import { db } from "../firebase";
import {
  Chip,
  Box,
  IconButton,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import MainCard from "components/MainCard";
import CompanyUsersList from "./CompanyUsersList";
import UnassignedUsersList from "./UnassignedUsersList";
import MachinesList from "./MachinesList";
import SearchFormEntries from "components/SearchFormEntries";
import { usersListQuery } from "../queries/usersList";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import { getRoleChipStyle } from "../utils/styleUtils";
import { alpha, useTheme } from "@mui/material/styles";
import "../assets/css/styles.css";
import {
  EditOutlined,
  SendOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

import BannerAdmin from "sections/dashboard/analytics/BannerAdmin";
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import IncomeChart from "sections/dashboard/analytics/IncomeChart";
import MarketingCardChart from "sections/dashboard/analytics/MarketingCardChart";
import OrdersCardChart from "sections/dashboard/analytics/OrdersCardChart";
import UsersCardChart from "sections/dashboard/analytics/UsersCardChart";
import SalesCardChart from "sections/dashboard/analytics/SalesCardChart";
import PageViews from "sections/dashboard/analytics/PageViews";
import OffRatioEvents from "sections/dashboard/analytics/OffRatioEvents";
import JumpToSection from "components/JumpToSection";

const CompanyPage = () => {
  const theme = useTheme();
  const [slot, setSlot] = useState("week");
  const [quantity, setQuantity] = useState("By volume");

  const handleQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment) setSlot(newAlignment);
  };
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  // Define refs
  const eventsRef = useRef(null);
  const systemsRef = useRef(null);
  const userListRef = useRef(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const companyRef = doc(db, "companies", id);
        const docSnap = await getDoc(companyRef);

        if (docSnap.exists()) {
          const companyData = docSnap.data();
          // Store the Firestore document reference in the state
          setCompany({ ...companyData, companyRef: companyRef });
        } else {
          console.log("No such company!");
        }
      } catch (error) {
        console.error("Error fetching company:", error);
      }
      setLoading(false);
    };

    fetchCompany();
  }, [id]);

  const handleScrollToEvents = (event) => {
    event.preventDefault();
    eventsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToSystems = (event) => {
    event.preventDefault();
    systemsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToUserList = (event) => {
    event.preventDefault();
    userListRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const sections = [
    { name: "Events", onClick: handleScrollToEvents },
    { name: "Systems", onClick: handleScrollToSystems },
    { name: "User List", onClick: handleScrollToUserList },
  ];

  return (
    <Grid container rowSpacing={4.5} columnSpacing={3}>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : company ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h1">
              {company.companyName} ({company.companyCode})
            </Typography>

            <JumpToSection sections={sections} />
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: "4rem" }}>
            {company && company.companyName ? (
              <SearchFormEntries companyName={company.companyName} />
            ) : (
              <Typography>No company found</Typography>
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <BannerAdmin />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticsDataCard
              title="Total Users"
              count="78,250"
              percentage={70.5}
            >
              <UsersCardChart />
            </AnalyticsDataCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticsDataCard
              title="Total Order"
              count="18,800"
              percentage={27.4}
              isLoss
              color="warning"
            >
              <OrdersCardChart />
            </AnalyticsDataCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticsDataCard
              title="Total Sales"
              count="$35,078"
              percentage={27.4}
              isLoss
              color="warning"
            >
              <SalesCardChart />
            </AnalyticsDataCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticsDataCard
              title="Total Marketing"
              count="$1,121,083"
              percentage={70.5}
            >
              <MarketingCardChart />
            </AnalyticsDataCard>
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Off-Ratio Events</Typography>
              </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Grid item>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      sx={{ ml: { xs: 0, sm: 2 }, mt: 3 }}
                      alignItems={{ xs: "center", sm: "flex-start" }}
                    >
                      <Stack direction="row" alignItems="center">
                        <CaretDownOutlined
                          style={{
                            color: theme.palette.error.main,
                            paddingRight: "4px",
                          }}
                        />
                        <Typography color={theme.palette.error.main}>
                          $1,12,900 (45.67%)
                        </Typography>
                      </Stack>
                      <Typography
                        color="textSecondary"
                        sx={{ display: "block" }}
                      >
                        Compare to : 01 Dec 2021-08 Jan 2022
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent={{ xs: "center", sm: "flex-end" }}
                      sx={{ mt: 3, mr: { xs: 0, sm: 2 } }}
                    >
                      <ToggleButtonGroup
                        exclusive
                        onChange={handleChange}
                        size="small"
                        value={slot}
                      >
                        <ToggleButton
                          disabled={slot === "week"}
                          value="week"
                          sx={{ px: 2, py: 0.5 }}
                        >
                          Week
                        </ToggleButton>
                        <ToggleButton
                          disabled={slot === "month"}
                          value="month"
                          sx={{ px: 2, py: 0.5 }}
                        >
                          Month
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <Select
                        value={quantity}
                        onChange={handleQuantity}
                        size="small"
                      >
                        <MenuItem value="By volume">By Volume</MenuItem>
                        <MenuItem value="By margin">By Margin</MenuItem>
                        <MenuItem value="By sales">By Sales</MenuItem>
                      </Select>
                      <IconButton
                        size="small"
                        sx={{
                          border: `1px solid ${theme.palette.grey[400]}`,
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <DownloadOutlined
                          style={{ color: theme.palette.grey[900] }}
                        />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ pt: 1 }}>
                <IncomeChart slot={slot} quantity={quantity} />
              </Box>
            </MainCard>
          </Grid>

           */}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            ref={eventsRef}
            className="remove-padding-top"
          >
            <OffRatioEvents companyName={company.companyName} />
          </Grid>

          <Grid item xs={12} ref={systemsRef}>
            <Typography variant="h5">Units & Systems List</Typography>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <MachinesList companyRef={company.companyRef} />
            </MainCard>
          </Grid>
          <Grid item xs={12} ref={userListRef}>
            <Typography variant="h5">Users at {company.companyName}</Typography>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <CompanyUsersList
                companyRef={company.companyRef}
                companyName={company.companyName}
              />
            </MainCard>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Unassigned Users List</Typography>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <UnassignedUsersList companyRef={company.companyRef} />
            </MainCard>
          </Grid>
        </>
      ) : (
        <Typography>No such company found.</Typography>
      )}
    </Grid>
  );
};

export default CompanyPage;
